import React from 'react'
import service1 from "../Assets/image10.webp";
import service6 from "../Assets/service1-1.webp";
import service3 from "../Assets/image3.webp";
import service2 from "../Assets/truck.webp";
import service4 from "../Assets/packers.webp";
import service5 from "../Assets/chain3.webp";
import { Link } from 'react-router-dom';
import { FaAngleRight } from 'react-icons/fa';

const CommonService = () => {
    const cardData = [
        {
          imageUrl: service1,
          heading: "Packers & Movers",
          buttonText: "Read More",
          description:
            "Moving to a new location may be a difficult undertaking, whether it's across town or state lines. Careful planning and dependable execution are necessary for the  Packers & Movers, packing, and secure shipping.",
            link: "/packers-movers-in-banglore",
        },
        {
          imageUrl: service2,
          heading: "Office Shifting",
          buttonText: "Read More",
          description:
            "Office relocation is a huge undertaking that requires careful planning, organising, and carrying out. It is crucial to have a trustworthy and knowledgeable moving service in a busy city like Bangalore. ",
            link: "/office-shifting-in-banglore",
        },
        {
          imageUrl: service3,
          heading: "Home Shifting",
          buttonText: "Read More",
          description:
            "Relocating to a new residence is an exciting time in life. This is where Bangalore's JSS Packers and Movers come into play, providing a full range of house moving services , hassle-free, and efficient.",
            link: "/home-shifting-in-banglore",
        },
        {
          imageUrl: service4,
          heading: "Storage Facilities​",
          buttonText: "Read More",
          description:
            "Whether you need temporary storage during a move or a long-term storage solution.Their state-of-the-art storage facilities are equipped with advanced security systems to keep your belongings safe. ",
            link: "/storage-facilities-in-banglore"
        },
        {
          imageUrl: service5,
          heading: "Bike & Car Shifting",
          buttonText: "Read More",
          description:
            "Transporting Vehicles Safely Is A Crucial Part Of Any Move. JSS Packers And Movers Offer Specialized Bike And Car Shifting Services In Bangalore, Ensuring Your Vehicle Reaches Its Destination In Perfect Condition.",
            link: "/bike-car-shifting-in-banglore"
        },
        {
          imageUrl: service6,
          heading: "International Shifting",
          buttonText: "Read More",
          description:
            "Moving abroad is a complex process that involves more than just packing and transportation. JSS Packers and Movers provide comprehensive international shifting services.",
            link: "/international-shifting-in-banglore"
        },
      ];
  return (
    <>
        {cardData.map((card, index) => (
              <div key={index} className="col-md-4">
                <div className="card-second">
                <Link to={card.link} ><img
                    src={card.imageUrl}
                    className="card-img-top"
                    alt="Card"
                  /> </Link>
                  
                  <div className="card-footer-second">
                  <Link to={card.link}> <h3>{card.heading}</h3></Link> 
                    <p className="card-text">{card.description}</p>
                    <Link to={card.link} className="custom-btn">
                      {card.buttonText}
                      <FaAngleRight />
                    </Link>
                  </div>
                </div>
              </div>
            ))}
    </>
  )
}

export default CommonService
