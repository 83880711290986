import React from "react";
import bg from "../Assets/foooter-final-bg-1.webp";
import {FaSquarePhoneFlip } from "react-icons/fa6";
import { FaClock, FaGlobe, FaShieldAlt, FaTruck } from "react-icons/fa";
import { Link } from "react-router-dom";

const Counter = () => {
  return (
    <div className=" bg-dark  overflow-hidden">
      <img src={bg} alt="" />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-3">
            <div
              className="text-center-two text-center"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              <span className="text-4xl font-weight-bold">
                <FaSquarePhoneFlip />
              </span>
              <p className="mt-4">24/7 Support Service</p>
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="text-center-two text-center"
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
            >
              <span className="text-4xl font-weight-bold">
                <FaGlobe />
              </span>
              <p className="mt-4">World Wide Service </p>
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="text-center-two text-center"
              data-aos="fade-up"
              data-aos-anchor-placement="bottom-bottom"
            >
              <span className="text-4xl font-weight-bold">
                <FaTruck />
              </span>
              <p className="mt-4">Delivery</p>
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="text-center-two text-center"
              data-aos="fade-up"
              data-aos-anchor-placement="bottom-bottom"
            >
              <span className="text-4xl font-weight-bold">
                <FaClock />
              </span>
              <p className="mt-4"> On Time Delivery</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Counter;
