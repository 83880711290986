import React, { useState, useEffect } from "react";
import "./Navbar.css"; // Assuming your styles are in this file
import "remixicon/fonts/remixicon.css";
import { Link } from "react-router-dom";
import logo from "../Assets/logoMain.png";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  FaAngleDown,
  FaBars,
  FaEnvelope,
  FaPhoneAlt,
  FaTimes,
} from "react-icons/fa";
import { FaHeadphonesSimple, FaLocationDot } from "react-icons/fa6";

// Assuming you have remixicon installed
const Navbar = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const showMenu = () => {
    setMenuVisible(true);
  };

  const hideMenu = () => {
    setMenuVisible(false);
  };

  const handleLinkClick = () => {
    hideMenu();
  };
  const [isHovered, setIsHovered] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <header className="header" id="header">
      <nav className="nav container">
        <Link to="/">
          <img src={logo} className="image-logo" alt="Logo" />
        </Link>

        <div
          className={`nav__menu ${menuVisible ? "show-menu" : ""}`}
          id="nav-menu"
        >
          <ul className="nav__list">
            <li className="nav__item">
              <Link to="/" className="nav__link" onClick={handleLinkClick}>
                <i className="ri-arrow-right-up-line"></i>
                <span>Home</span>
              </Link>
            </li>
            <li className="nav__item">
              <Link to="/about" className="nav__link" onClick={handleLinkClick}>
                <i className="ri-arrow-right-up-line"></i>
                <span>About Us</span>
              </Link>
            </li>
            <li className="nav__item nav-item">
              <Link to="/service" className="nav__link">
                <i className="ri-arrow-right-up-line"></i>
                <span onClick={handleLinkClick}>Service</span>
              </Link>
              <FaAngleDown
                className="angle-down"
                onMouseEnter={() => setIsHovered(true)}
                onClick={() => setIsHovered(!isHovered)}
              />
              {isHovered && (
                <ul
                  className="dropdown-main"
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <li onClick={handleLinkClick}>
                    {" "}
                    <Link to="/packers-movers-in-banglore">
                      Packers & Movers
                    </Link>
                  </li>
                  <li onClick={handleLinkClick}>
                    {" "}
                    <Link to="/office-shifting-in-banglore">
                      Office Shifting
                    </Link>
                  </li>
                  <li onClick={handleLinkClick}>
                    {" "}
                    <Link to="/home-shifting-in-banglore">Home Shifting</Link>
                  </li>
                  <li onClick={handleLinkClick}>
                    {" "}
                    <Link to="/storage-facilities-in-banglore">
                      Storage Facilities
                    </Link>
                  </li>
                  <li onClick={handleLinkClick}>
                    {" "}
                    <Link to="/bike-car-shifting-in-banglore">
                      Bike & Car Shifting
                    </Link>
                  </li>
                  <li onClick={handleLinkClick}>
                    {" "}
                    <Link to="/international-shifting-in-banglore">
                      International Shifting
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li className="nav__item">
              <Link
                to="/contact"
                className="nav__link"
                onClick={handleLinkClick}
              >
                <i className="ri-arrow-right-up-line"></i>
                <span>Contact</span>
              </Link>
            </li>
            <li className="nav__item">
              <Link
                to="/gallary"
                className="nav__link"
                onClick={handleLinkClick}
              >
                <i className="ri-arrow-right-up-line"></i>
                <span>Our Gallary </span>
              </Link>
            </li>
            <li className="nav__item">
             
              <Link
                className="location-main mobile-display"
                to="mailto:support@jsspackersmovers.in"
              >
                <FaEnvelope className="nav-icon" />
                support@jsspackersmovers.in
              </Link>


              
            </li>
            <Link
                className="location-main two mobile-display mb-3"
                to="tel:9686825980"
              >
                Talk to our Expert <br />
                +91-96868-25980
                <FaHeadphonesSimple className="nav-icon  " />
              </Link>
          </ul>

          <Link to="/">
            <img
              src={logo}
              className="image-logo nalogo mobile-display"
              alt="Logo"
            />
          </Link>

          <div className="nav__close" id="nav-close" onClick={hideMenu}>
            <i className="ri-close-large-line"></i>
          </div>

          <div className="nav__social">
            <li
              className="mobile-view nav-link"
              aria-current="page"
              onClick={handleShow}
            >
              <FaBars />
            </li>
          </div>
        </div>

        <div className="nav__toggle" id="nav-toggle" onClick={showMenu}>
          <i className="ri-menu-line"></i>
        </div>

        <Offcanvas show={show} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton />
          <Offcanvas.Body className="color">
            <div id="qodef-side-area-inner">
              <div
                id="block-21"
                className="widget widget_block widget_media_image"
                data-area="side-area"
              >
                <img
                  decoding="async"
                  src={logo}
                  alt="Shine  Packers & Movers"
                  className="wp-image-8072"
                  width="220"
                  height="100"
                  sizes="(max-width: 202px) 100vw, 202px"
                />
              </div>
              <div
                id="block-22"
                className="widget widget_block"
                data-area="side-area"
              ></div>
              <div
                id="block-10"
                className="widget widget_block"
                data-area="side-area"
              >
                <div className="number-main">Support center 24/7 :</div>

                <p>
                  <Link className="location-main" to="tel:9686825980">
                    <FaPhoneAlt className="nav-icon" />
                    +91-96868-25980
                  </Link>
                </p>
              </div>
              <div
                id="block-12"
                className="widget widget_block"
                data-area="side-area"
              >
                <div className="wp-block-columns is-layout-flex wp-container-5">
                  <div className="wp-block-column is-layout-flow">
                    <div className="number-main">You can find us at :</div>
                    <p>
                      <Link
                        className="location-main"
                        to=""
                        target="_blank"
                        rel="noopener"
                      >
                        {" "}
                        <FaLocationDot className="nav-icon" /> B-20 , 4Th Main
                        Road Dduttl Suburb, Near Ks Automobiles, Narasimha
                        Layout, Yeswanthpur, Bengaluru, Karnataka 560022
                      </Link>
                    </p>
                  </div>

                  <div className="wp-block-column is-layout-flow">
                    <div className="number-main">Get in touch with us :</div>
                    <p>
                      <Link
                        className="location-main"
                        to="mailto:info@jsspackersmovers.in"
                      >
                        <FaEnvelope className="nav-icon" />
                        info@jsspackersmovers.in
                      </Link>
                      <br />
                      <Link
                        className="location-main"
                        to="mailto:invoice@jsspackersmovers.in"
                      >
                        <FaEnvelope className="nav-icon" />
                        invoice@jsspackersmovers.in
                      </Link>
                      <br />
                      <Link
                        className="location-main"
                        to="mailto:support@jsspackersmovers.in"
                      >
                        <FaEnvelope className="nav-icon" />
                        support@jsspackersmovers.in
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </nav>
    </header>
  );
};

export default Navbar;
