import React, { useEffect } from 'react';
import { FaAngleRight } from 'react-icons/fa';
import cta1 from "../Assets/cta1-1.webp";
import { Link } from 'react-router-dom';
import FormMain from './Form';
import CommonService from './CommonService';

const Service = () => {

 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>


      
      <section className="page-title page-titles">
        <div className="container">
          <div className=" text-center-padding">
            <h1 className="title">Services</h1>
            <ul className="page-breadcrumb-two">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <FaAngleRight />
              </li>
              <li>Services</li>
            </ul>
          </div>
        </div>
      </section>

    

      <section className="services-section">
        <div className="container">
          <div className="outer-box-service">
            <h2>Efficiently Relocating, Safely Packing, and Meticulously Handling Belongings with Care and Expertise through JSS Packers and Movers for Stress-Free Moving Solutions</h2>
            <div className="services-grid">

            
<CommonService />
             
          
        
            </div>
          </div>
        </div>
      </section>

      <section className="call-to-action">
        <div className="container">
          <div className="bg bg-pattern-1">
            <div className="content-box-contact">
              <h2 className="title words-slide-up text-split">
                24/7 customer support any time of the day or night
              </h2>
              <div className="text">
                Customers can get help and find answers to questions as soon
              </div>
              <div className="btn-box-contact">
                <Link to="/contact" className="theme-btn btn-style-one">
                  <span className="btn-title">Request a Quote</span>
                </Link>
                <Link to="/contact" className="theme-btn btn-style-one">
                  <span className="btn-title">Contact Us</span>
                </Link>
              </div>
            </div>
            <div className="image-box-contact">
              <img src={cta1} alt="" />
            </div>
          </div>
        </div>
      </section>

      <FormMain />
    </>
  );
}

export default Service;
