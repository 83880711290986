import React, { useRef, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import last from "../Assets/men.webp"
import test1 from "../Assets/team1-1.webp"
import comment from "../Assets/commennt.webp"
import test2 from "../Assets/team1-2.webp"
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaArrowLeft, FaArrowRight, FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';

const testimonials = [
    {
        text: "JSS Packers and Movers provided exceptional service, making our office relocation seamless and stress-free. ",
        img: test1,
        name: "Rajesh Kumar",
        profession: "Marketing Manager"
    },
    {
        text: "Professional and reliable movers. Our international relocation was handled perfectly, and all items arrived safely. Excellent job!",
        img: comment,
        name: "Arjun Mehta",
        profession: "CEO"
    },
   
    {
        text: "The real-time tracking feature was incredibly helpful. JSS Packers and Movers made our interstate move smooth and worry-free",
        img: test1,
        name: " Vikram Singh",
        profession: " IT Specialist"
    },
    {
        text: " Fantastic experience! The team was professional, efficient, and took great care of our belongings.Very satisfied with the service",
        img: test2,
        name: "Anita Sharma",
        profession: "Homeowner"
    },
];

const Testimonials = () => {
    useEffect(() => {
        AOS.init({
            duration: 1200,
            once: true,
        });
    }, []);
    const sliderRef = useRef(null);

    const settings = {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        speed: 400,
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <section className="features-section-two">
        <div className="bg testimonial-image"></div>
        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-7" data-aos="fade-up">
                    <div className="testimonials-two-wrap mb-55">
                        <div className="d-flex align-items-center">
                            <div className="section-title mb-50">
                                <span className="sec-title">Testimonials</span>
                                <h2>What Our Customers Say About Our Services</h2>
                            </div>
                            <div className="testimonials-arrow pt-25 rpt-10">
                                <button className="testi-prev" onClick={() => sliderRef.current.slickPrev()}>
                                    <FaArrowLeft />
                                </button>
                                <button className="testi-next" onClick={() => sliderRef.current.slickNext()}>
                                    <FaArrowRight />
                                </button>
                            </div>
                        </div>
                        <Slider ref={sliderRef} {...settings} className="testimonials-slider">
                            {testimonials.map((testimonial, index) => (
                                <div className="testimonial-item-two" key={index} data-aos="fade-up">
                                    <div className="text">
                                        <FaQuoteLeft className='svg-one' />
                                        {testimonial.text}
                                        <FaQuoteRight className='svg-two' />
                                    </div>
                                    <div className="testi-footer">
                                        <img src={testimonial.img} alt="Testimonial Author" />
                                        <div className="author">
                                            <h6>{testimonial.name}</h6>
                                            <span className="designation">{testimonial.profession}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                <div className="col-md-5" >
                    <div className="testimonials-image-two mb-55">
                        <img className="" src={last} alt="Testimonials" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
};

export default Testimonials;
