import React from 'react';
import image1 from "../Assets/image1.webp";
import image2 from "../Assets/image2.webp";
import image3 from "../Assets/image3.webp";
import image4 from "../Assets/image4.webp";
import image5 from "../Assets/image5.webp";
import image6 from "../Assets/image6.webp";
import image7 from "../Assets/image7.webp";
import image8 from "../Assets/image8.webp";
import image9 from "../Assets/image9.webp";
import image10 from "../Assets/image10.webp";
import image11 from "../Assets/image11.webp";
import image12 from "../Assets/im.webp";
import image13 from "../Assets/image13.webp";
import image14 from "../Assets/image14.webp";

const photos = [
  { src: image1, alt: "expert delivery packers and movers" },
  { src: image2, alt: "packers and movers in Pune" },
  { src: image3, alt: "expert delivery packers and movers in Pune" },
  { src: image4, alt: "best packers and movers in pune" },
  { src: image5, alt: "packers and movers pune near me" },
  { src: image6, alt: "transportation in pune" },
  { src: image7, alt: "home shifting services in pune" },
  { src: image8, alt: "storage services in pune" },
  { src: image9, alt: "expert delivery pune transport services" },
  { src: image10, alt: "pune transport services" },
  { src: image11, alt: "transportation services in pune" },
  { src: image12, alt: "pune transportation services" },
  { src: image13, alt: "pune transportation services" },
  { src: image14, alt: "pune transportation services" }
];

const PhotoGallery = () => {
  return (
    <div className="container">
    <div className="photo-gallery">
      {photos.map((photo, index) => (
        <div key={index} className="photo-item">
          <img src={photo.src} alt={photo.alt} />
        </div>
      ))}
      </div>
    </div>
  );
};

export default PhotoGallery;
