import React, {useEffect} from 'react'
import { Link } from "react-router-dom";
import viber from "../Assets/viber.webp"
import whatsapp from "../Assets/whatsapp.webp"

import { FaAngleDoubleRight, FaAngleDoubleUp, FaAngleUp, FaFacebook, FaInstagram, FaMapPin, FaPaperPlane, FaPinterestP, FaTwitter } from 'react-icons/fa';
import thumb1 from "../Assets/gallery-thumb-1.webp"
import thumb2 from "../Assets/gallery-thumb-2.webp"
import thumb3 from "../Assets/gallery-thumb-3.webp"


const Footer = () => {
//   useEffect(() => {
//     const scrollToTopButton = document.querySelector('.scroll-to-top');
//     scrollToTopButton.addEventListener('click', () => {
//         window.scrollTo({
//             top: 0,
//             behavior: 'smooth',
//         });
//     });
//     return () => {
//         scrollToTopButton.removeEventListener('click', () => {});
//     };
// }, []);


  return (
    <>

<div className="icon-container">
      <a className="icon call" target='blank' href="tel:9686830180">
        <img
          className="icon-image icon-image-call"
          src={viber}
          alt="Call"
        />
      </a>
      <a className="icon whatsapp" target='blank' href="https://wa.me/9686825980">
        <img
          className="icon-image icon-image-whatsapp"
          src={whatsapp}
          alt="WhatsApp"
        />
      </a>
    </div>

     <div className="widgets-section">
     <div class="bg bg-image" ></div>
      <div className="container">
        <div className="row paading-main">

          <div className="footer-column col-lg-3 col-sm-6">
            <div className="footer-widget about-widget">
            <h4 className="widget-title">JSS Packers & Movers</h4>
             
              <div className="text-footer text-justify">At JSS Packers and Movers, we pride ourselves on our commitment to excellence and customer satisfaction.</div>
              <div className="widget-content">
             
                
            </div>
              <Link to="/about" className="theme-btn btn-style-one"><span className="btn-title">About Us</span></Link>
            </div>
            
          </div>

          <div className="footer-column col-lg-3 col-sm-6">
            <div className="footer-widget links-widget">
              <h4 className="widget-title">Our Service</h4>
              <div className="widget-content">
                <ul className="user-links">
                  <li><FaAngleDoubleRight /> <Link to="/packers-movers-in-banglore"> Packers & Movers </Link> </li>
                  <li><FaAngleDoubleRight /> <Link to="/office-shifting-in-banglore"> Office Shifting </Link> </li>
                  <li><FaAngleDoubleRight />  <Link to="/home-shifting-in-banglore">  Home Shifting</Link></li>
                  <li><FaAngleDoubleRight /> <Link to="/storage-facilities-in-banglore"> Storage Facilities</Link> </li>
                  <li><FaAngleDoubleRight /> <Link to="/bike-car-shifting-in-banglore"> Bike & Car Shifting </Link></li>
                  <li><FaAngleDoubleRight /> <Link to="/international-shifting-in-banglore">  Internatoinal Shifting </Link> </li>


                 
                
                
                
                 
                
                </ul>
              </div>
            </div>
          </div>
          <div className="footer-column col-lg-3 col-sm-6">
            <div className="footer-widget links-widget">
              <h4 className="widget-title">Connect with Us</h4>
              <div className="widget-content">
             
                <ul className="user-links">
                  <li><FaAngleDoubleRight /> <Link to="/heavy-haul">About Us</Link></li>
                  <li><FaAngleDoubleRight /> <Link to="/privacy-policy">Privacy & Policy</Link></li>
                  <li><FaAngleDoubleRight /> <Link to="/terms-conditions">Terms & Condition</Link></li>
                  <li><FaAngleDoubleRight /> <Link to="#">Our Projects</Link></li>
                  <div className="widget-content">
                <div className="insta-gallery mt-4">
                  <figure className="image"><Link to="/service"><img src={thumb1} alt='' /></Link></figure>
                  <figure className="image"><Link to="/service"><img src={thumb2} alt='' /></Link></figure>
                  <figure className="image"><Link to="/service"><img src={thumb3} alt='' /></Link></figure>
                 
                </div>
              </div>
                </ul>
              </div>
            </div>
          </div>

          

          <div className="footer-column col-lg-3 col-sm-6">
            <div className="footer-widget newsletter-widget">
              <h4 className="widget-title">Newsletter</h4>
              <div className="text-footer">Subscribe our newsletter to get our latest update &amp; news</div>

              <div className="newsletter-form">
                <div className="form-group">
                  <input type="email" name="Email" placeholder="Email....." required="" />
                  <button type="submit" className="form-btn"><FaPaperPlane /></button>
                </div>
              </div>

             
           <p>Follow Us :</p>
              <ul className="social-icon-one light">
                        <li><Link to="https://www.facebook.com/"><FaFacebook /></Link></li>
                        <li><Link to="https://www.twitter.com/"><FaTwitter /></Link></li>
                        <li><Link to="https://www.pinterest.com/"><FaPinterestP /></Link></li>
                        <li><Link to="https://www.instagram.com/"><FaInstagram /></Link></li>
                    </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
                <div className="inner-container">
                    
                    <div className="copyright-text">© 2024 JSS Packers & Movers. All Rights Reserved. Developed By <Link to="https://futuretouch.in/" target="_blank" style={{fontWeight:"700"}}>Future Touch</Link></div>

                    {/* <div className="scroll-to-top scroll-to-target" data-target="html" style={{ display: 'inline' }}>
                       <FaAngleDoubleUp />
                    </div> */}
                  
                </div>
        </div>
    </div> 
   
    </>
  )
}

export default Footer
