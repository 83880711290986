// PopupForm.js
import React from 'react';
import FormTwo from './FormTwo';
import { FaTimes } from 'react-icons/fa';
import packers2 from "../Assets/packers2.png"

const PopupForm = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-main">
        <FaTimes className="close-button" onClick={onClose} />
        
      <FormTwo  onClose={onClose}  />
        <img src={packers2} alt="" className='popup-image'/>
      </div>
    </div>
  );
};

export default PopupForm;
