import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import image1 from "../Assets/chain1.webp"
import image2 from "../Assets/chain2.webp"
import image3 from "../Assets/chain3.webp"
import { FaAngleRight, FaEnvelope, FaPencilAlt, FaPhoneAlt, FaUserAlt } from 'react-icons/fa'
import Process from './Process';
import heavyhaul from "../Assets/1690218120179.webp"

function SupplyChain() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     <section className="page-title page-titles">
    <div className="container">
      <div className="banner-section">
       <div className=" page-content">
       <h1 className="title">Bike & Car Shifting Service</h1>
        <ul className="page-breadcrumb">
         
          <li>Transporting vehicles safely is a crucial part of any move. JSS Packers and Movers offer specialized bike and car shifting services in Bangalore, ensuring your vehicle reaches its destination in perfect condition. They use advanced techniques and equipment to handle your vehicle with the utmost care, providing secure loading, transportation, and unloading services.</li>
        </ul>
       </div>
      <img src={heavyhaul} alt="" />
      </div>

    </div>
  </section> 

      <section className="single-service">
        <div className="container">
          <div className="row">
          <div className="col-md-4">
            <div className="service-page1-right">
              <div className="service-menu">
                <h6>Service Menu</h6>
                <ul>
                <Link to="/packers-movers-in-banglore"> <li>Packers & Movers <FaAngleRight /></li></Link> 
                <Link to="/office-shifting-in-banglore">  <li>Office Shifting <FaAngleRight /></li></Link> 
                <Link to="/home-shifting-in-banglore">  <li>Home Shifting<FaAngleRight /></li></Link> 
                <Link to="/storage-facilities-in-banglore">  <li>Storage Facilities<FaAngleRight /></li></Link> 
                <Link to="/bike-car-shifting-in-banglore">  <li>Bike & Car Shifting <FaAngleRight /></li></Link> 
                <Link to="/international-shifting-in-banglore">   <li>International Shifting <FaAngleRight /></li></Link> 
                 
                </ul>
              </div>
              <div className="service-menu">
                <h6>Fill The Form</h6>
                <div className="form-fill">  <span>
                  <FaUserAlt /></span>
                  <input type="text" placeholder="Full Name" />
                  <span><FaEnvelope /></span>
                  <input type="email" placeholder="Your Email" />
                  <span><FaPhoneAlt /></span>
                  <input type="number" placeholder="Phone Number" />
                  <span><FaPencilAlt /></span>
                  <textarea name="" id="" cols="30" rows="7"></textarea>
                  <button>Send Now</button>
                </div>
              </div>
            </div> 
            </div>
            <div className="col-md-8">
              <div className="service-page1-left">
                <div className="page1-img-text">
                  <img src={image1} alt="" />
                  <h2>Bike & Car Shifting Service by JSS Packers and Movers in Bangalore </h2>
                  <p>
                  Relocating to a different location or even within the same city can be difficult, particularly if you have sentimental automobiles to move. A dependable service provider is necessary to ensure the safe and prompt removal of your automobile or bike in Bangalore, a city renowned for its busy traffic and fast-paced lifestyle. Reputable in the moving business, JSS Packers and Movers provides thorough bike and automobile moving services that are notable for their effectiveness, security, and client-focused methodology.
                  </p>
                  <p>

                  JSS Packers and Movers is aware of the emotional and financial significance of your cars. Their vehicle and bike moving service is intended to provide a smooth and stress-free transaction. JSS offers end-to-end solutions for vehicle relocation, whether it is within Bangalore or to another city. This covers everything, from the preliminary examination through the cautious packing, safe loading, and secure transportation, to the prompt delivery at the intended site. </p>
                  <p>JSS Packers and Movers' unique packing methods are among its best features. High-quality packing supplies, like bubble wrap, corrugated sheets, and plastic coverings, are used to package bikes so that they are shielded from any dents, scratches, and other damages while in transport. Cars are also handled with extreme caution. The exterior and inside of the car are protected from damage by strong containers and specially manufactured coverings.</p>
                  <h3>Advanced Equipment and Technology</h3>
                  <img src={image2} alt="" />
                  <p>JSS Packers and Movers manages the transportation process with cutting-edge machinery and technology. Vehicles are loaded and transported using secure trailers, hydraulic lifts, and contemporary car carriers. These instruments not only guarantee the security of the automobiles but also improve the effectiveness of the procedure. Another important aspect is GPS-enabled tracking devices, which give clients peace of mind by enabling them to track the whereabouts of their vehicle in real time while it is in transit.</p>
                  <h5>Experienced and Skilled Staff</h5>
                  <p>
                  The personnel at JSS Packers and Movers possesses extensive training and expertise in managing car moves. They have the technological know-how to operate a variety of vehicles, from a simple scooter to an upscale luxury sedan. Their knowledge guarantees that your car will be moved with the highest care during the entire process. The group also offers individualised services since they recognise that every car and every relocation are distinct and call for a special strategy.</p>

                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="page1-left-text">
                      <h6>Conclusion</h6>
                      <p>
                      In a city like Bangalore, moving your automobile or bike might be a difficult undertaking, but with JSS Packers and Movers, it becomes a hassle-free experience. Many people choose them because of their extensive vehicle shifting options, cutting-edge technology, knowledgeable team, and customer-focused philosophy.</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="page1-right-pic">
                      <img src={image3} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <Process />
    </>
  );
}

export default SupplyChain;


