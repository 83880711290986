import React, { useEffect } from "react";
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import image1 from "../Assets/disclaimer.webp";


const Diclaimer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="page-title">
        <div className="container">
          <div className="  text-center-padding">
            <h1 className="title">Terms & Condition</h1>
            <ul className="page-breadcrumb-two">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <FaAngleRight />
              </li>
              <li>Terms & Condition</li>
            </ul>
          </div>
        </div>
      </section>

        <div className="container">
      <div className="privacy">
        <div className="privacy-content">
        <h2>Terms and Conditions

</h2>
        <p>
        By engaging JSS Packers and Movers, you agree to our terms and conditions. Our company provides packing, moving, and related services as detailed in the service agreement. All bookings must be confirmed and paid for in advance, unless otherwise specified. Cancellations must be made within the timeframe specified in your contract to avoid penalties. We are not liable for delays or damages caused by factors beyond our control, including but not limited to weather conditions or unforeseen logistical issues. Please review our full terms and conditions for detailed information on cancellations, refunds, and other important policies.
        </p>
        </div>
        <img src={image1} alt="" />
        
      </div>
      </div>
    </>
  );
};

export default Diclaimer;
