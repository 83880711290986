import './App.css';
import Footer from './Components/Navbar/Footer';
import Navbar from './Components/Navbar/Navbar';
import TopBar from './Components/Navbar/TopBar';
import Home from './Components/Pages/Home';
import { Route, Routes } from "react-router-dom";
import Contact from './Components/Pages/Contact';
import Service from './Components/Pages/Service';
import About from './Components/Pages/About';
import HeavyHaul from './Components/Pages/HeavyHaul';
import Warehousing from './Components/Pages/Warehousing​';
import SupplyChain from './Components/Pages/SupplyChain';
import LTLFreight from './Components/Pages/LTLFreight';
import Packaging from './Components/Pages/Packaging';
import Ocean from './Components/Pages/Ocean';
import 'remixicon/fonts/remixicon.css';
import Privacy from './Components/Pages/Privacy';
import Disclaimer from './Components/Pages/Disclaimer';
import PhotoGallery from './Components/Pages/Gallary';

  
function App() {
  return (
  <>
  <TopBar />
  <Navbar />
  <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/packers-movers-in-banglore" element={<HeavyHaul />} />
        <Route path="/storage-facilities-in-banglore" element={<Warehousing />} />
        <Route path="/bike-car-shifting-in-banglore" element={<SupplyChain />} />
        <Route path="/office-shifting-in-banglore" element={<LTLFreight />} />
        <Route path="/international-shifting-in-banglore" element={<Packaging />} />
        <Route path="/home-shifting-in-banglore" element={<Ocean />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms-conditions" element={<Disclaimer />} />
        <Route path="/gallary" element={<PhotoGallery />} />
      </Routes>
  <Footer />  

  </>
  );
}

export default App;
