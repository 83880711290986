import React, { useEffect, useState } from "react";

import AOS from "aos";
import "aos/dist/aos.css";
import air from "../Assets/flight-delivery.webp";
import { Link } from "react-router-dom";
import Testimonials from "./Testimonial";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import move from "../Assets/Moving--Sto.webp";

import second from "../Assets/request-one_pattern.webp";
import move2 from "../Assets/custom_solutions.webp";
import track from "../Assets/track-order.webp";
import Choose from "./Choose";
import Counter from "./Counter";

import FormMain from "./Form";
import Process from "./Process";
import { FaArrowCircleRight, FaUser } from "react-icons/fa";

import delivery4 from "../Assets/cost.webp";
import Common from "./Common";
import {
  FaBoxesStacked,
  FaDiamondTurnRight,
  FaTruckFront,
} from "react-icons/fa6";

import sliderImg1 from "../Assets/home-7-slide3.webp";
import sliderImg2 from "../Assets/home-7-slide2.webp";
import sliderImg3 from "../Assets/home-7-slide4.webp";
import CommonService from "./CommonService";
import PopupForm from "./PopUpForm";

const Home = () => {
  useEffect(() => {
    // window.scrollTo(0, 0);
    // AOS.init({
    //   duration: 1200, // Customize this value as needed
    // });
  }, []);

  const servicesData = [
    {
      title: "Our Services In Pune",
      services: [
        { name: "JSS Packers Movers in Abohar" },
        { name: "JSS Packers Movers in Adajan" },
        { name: "JSS Packers Movers in Adugodi" },
        { name: "JSS Packers Movers in Adyar" },
        { name: "JSS Packers Movers in Agartala" },
        { name: "JSS Packers Movers in Agra" },
        { name: "JSS Packers Movers in Ahmedabad" },
        { name: "JSS Packers Movers in Ahmednagar" },
        { name: "JSS Packers Movers in Airoli" },
        { name: "JSS Packers Movers in Ajmer" },
        { name: "JSS Packers Movers in Akhnoor" },
        { name: "JSS Packers Movers in Akola" },
        { name: "JSS Packers Movers in Aligarh" },
        { name: "JSS Packers Movers in Allahabad" },
        { name: "JSS Packers Movers in Alwar" },
        { name: "JSS Packers Movers in Ambala" },
        { name: "JSS Packers Movers in Ambattur" },
        { name: "JSS Packers Movers in Ambernath" },
        { name: "JSS Packers Movers in Ameenpur" },
        { name: "JSS Packers Movers in Ameerpet" },
        { name: "JSS Packers Movers in Amravati" },
        { name: "JSS Packers Movers in Amritsar" },
        { name: "JSS Packers Movers in Anand" },
        { name: "JSS Packers Movers in Anantapur" },
        { name: "JSS Packers Movers in Anantnag" },
        { name: "JSS Packers Movers in Andheri" },
        { name: "JSS Packers Movers in Ankleshwar" },
        { name: "JSS Packers Movers in Asansol" },
        { name: "JSS Packers Movers in Aundh" },
        { name: "JSS Packers Movers in Aurangabad" },
        { name: "JSS Packers Movers in Awantipora" },
        { name: "JSS Packers Movers in Baddi" },
        { name: "JSS Packers Movers in Badgam" },
        { name: "JSS Packers Movers in Badlapur" },
        { name: "JSS Packers Movers in Bahadurgarh" },
        { name: "JSS Packers Movers in Balewadi" },
        { name: "JSS Packers Movers in Balkum" },
        { name: "JSS Packers Movers in Banashankari" },
        { name: "JSS Packers Movers in Banaswadi" },
        { name: "JSS Packers Movers in Bandipora" },
        { name: "JSS Packers Movers in Bandra" },
        { name: "JSS Packers Movers in Baner" },
        { name: "JSS Packers Movers in Bangalore" },
        { name: "JSS Packers Movers in Bannerghatta Road" },
        { name: "JSS Packers Movers in Baramati" },
        { name: "JSS Packers Movers in Baramulla" },
        { name: "JSS Packers Movers in Bardhaman" },
        { name: "JSS Packers Movers in Bareilly" },
      
      ],
    },
    {
      title: "Our Services In Bangalore",
      services: [
        { name: "JSS Packers Movers in Barnala" },
        { name: "JSS Packers Movers in Baroda" },
        { name: "JSS Packers Movers in Barwala" },
        { name: "JSS Packers Movers in Batala" },
        { name: "JSS Packers Movers in Bathinda" },
        { name: "JSS Packers Movers in Beas" },
        { name: "JSS Packers Movers in Beawar" },
        { name: "JSS Packers Movers in Beeramguda" },
        { name: "JSS Packers Movers in Begumpet" },
        { name: "JSS Packers Movers in Begur Road" },
        { name: "JSS Packers Movers in Behala" },
        { name: "JSS Packers Movers in Belapur" },
        { name: "JSS Packers Movers in Belgaum" },
        { name: "JSS Packers Movers in Bellandur" },
        { name: "JSS Packers Movers in Bellary" },
        { name: "JSS Packers Movers in Bhagalpur" },
        { name: "JSS Packers Movers in Berhampur" },
        { name: "JSS Packers Movers in Bhandup" },
        { name: "JSS Packers Movers in Bharuch" },
        { name: "JSS Packers Movers in Bhavnagar" },
        { name: "JSS Packers Movers in Bhayander" },
        { name: "JSS Packers Movers in Bhel" },
        { name: "JSS Packers Movers in Bhilai" },
        { name: "JSS Packers Movers in Bhilwara" },
        { name: "JSS Packers Movers in Bhimavaram" },
        { name: "JSS Packers Movers in Bhiwadi" },
        { name: "JSS Packers Movers in Bhiwani" },
        { name: "JSS Packers Movers in Bhopal" },
        { name: "JSS Packers Movers in Bhubaneswar" },
        { name: "JSS Packers Movers in Bhuj" },
        { name: "JSS Packers Movers in Bhuntar" },
        { name: "JSS Packers Movers in Bihar" },
        { name: "JSS Packers Movers in Bikaner" },
        { name: "JSS Packers Movers in Bilaspur" },
        { name: "JSS Packers Movers in Boisar" },
        { name: "JSS Packers Movers in Bokaro" },
        { name: "JSS Packers Movers in Bommanahalli" },
        { name: "JSS Packers Movers in Borivali" },
        { name: "JSS Packers Movers in Bowenpally" },
        { name: "JSS Packers Movers in Byculla" },
        { name: "JSS Packers Movers in Calicut" },
        { name: "JSS Packers Movers in Chamba" },
        { name: "JSS Packers Movers in Chandanagar" },
        { name: "JSS Packers Movers in Chandigarh" },
        { name: "JSS Packers Movers in Chandivali" },
        { name: "JSS Packers Movers in Chandrapur" },
        { name: "JSS Packers Movers in Chembur" },
        { name: "JSS Packers Movers in Chennai" },
        { name: "JSS Packers Movers in Chinchwad" },
        { name: "JSS Packers Movers in Chittoor" },
        { name: "JSS Packers Movers in Chittorgarh" },
        { name: "JSS Packers Movers in Chrompet" },
        { name: "JSS Packers Movers in Churchgate" },
        { name: "JSS Packers Movers in Cochin" },
        { name: "JSS Packers Movers in Coimbatore" },
        { name: "JSS Packers Movers in Colaba" },
        { name: "JSS Packers Movers in Cuttack" },
        { name: "JSS Packers Movers in Dabwali" },
        { name: "JSS Packers Movers in Dadar" },
        { name: "JSS Packers Movers in Dahisar" },
        { name: "JSS Packers Movers in Darbhanga" },
        { name: "JSS Packers Movers in Dehradun" },
        { name: "JSS Packers Movers in Delhi" },
        { name: "JSS Packers Movers in Derabassi" },
        { name: "JSS Packers Movers in Dewas" },
      ],
    },
    {
      title: "All India Services",
      services: [
        { name: "JSS Packers Movers in Dhanbad" },
        { name: "JSS Packers Movers in Dharamshala" },
        { name: "JSS Packers Movers in Dilsukhnagar" },
        { name: "JSS Packers Movers in Dindigul" },
        { name: "JSS Packers Movers in Doda" },
        { name: "JSS Packers Movers in Dombivli" },
        { name: "JSS Packers Movers in Domlur" },
        { name: "JSS Packers Movers in Dumdum" },
        { name: "JSS Packers Movers in Durg" },
        { name: "JSS Packers Movers in Durgapur" },
        { name: "JSS Packers Movers in Dwarka" },
        { name: "JSS Packers Movers in Dwarka Sector 7" },
        { name: "JSS Packers Movers in Ejipura" },
        { name: "JSS Packers Movers in Electronic City" },
        { name: "JSS Packers Movers in Eluru" },
        { name: "JSS Packers Movers in Ernakulam" },
        { name: "JSS Packers Movers in Erode" },
        { name: "JSS Packers Movers in Faizabad" },
        { name: "JSS Packers Movers in Faridabad" },
        { name: "JSS Packers Movers in Fatehabad" },
        { name: "JSS Packers Movers in Fazilka" },
        { name: "JSS Packers Movers in Firozpur" },
        { name: "JSS Packers Movers in Gachibowli" },
        { name: "JSS Packers Movers in Gandhidham" },
        { name: "JSS Packers Movers in Gandhinagar" },
        { name: "JSS Packers Movers in Ganganagar" },
        { name: "JSS Packers Movers in Garia" },
        { name: "JSS Packers Movers in Gaya" },
        { name: "JSS Packers Movers in Ghansoli" },
        { name: "JSS Packers Movers in Ghatkopar East" },
        { name: "JSS Packers Movers in Ghaziabad" },
        { name: "JSS Packers Movers in Goa" },
        { name: "JSS Packers Movers in Gopanpally" },
        { name: "JSS Packers Movers in Gorakhpur" },
        { name: "JSS Packers Movers in Goregaon West" },
        { name: "JSS Packers Movers in Guwahati" },
        { name: "JSS Packers Movers in Gwalior" },
        { name: "JSS Packers Movers in Haldwani" },
        { name: "JSS Packers Movers in Halol" },
        { name: "JSS Packers Movers in Hampi" },
        { name: "JSS Packers Movers in Hansi" },
        { name: "JSS Packers Movers in Haridwar" },
        { name: "JSS Packers Movers in Hazaribagh" },
        { name: "JSS Packers Movers in Hoshangabad" },
        { name: "JSS Packers Movers in Howrah" },
        { name: "JSS Packers Movers in Hyderabad" },
        { name: "JSS Packers Movers in Ichalkaranji" },
        { name: "JSS Packers Movers in Indore" },
        { name: "JSS Packers Movers in Jabalpur" },
        { name: "JSS Packers Movers in Jagdalpur" },
        { name: "JSS Packers Movers in Jalandhar" },
        { name: "JSS Packers Movers in Jalgaon" },
        { name: "JSS Packers Movers in Jalore" },
        { name: "JSS Packers Movers in Jammu" },
        { name: "JSS Packers Movers in Jamnagar" },
        { name: "JSS Packers Movers in Jamshedpur" },
        { name: "JSS Packers Movers in Jodhpur" },
        { name: "JSS Packers Movers in Jogeshwari" },
        { name: "JSS Packers Movers in Jorhat" },
        { name: "JSS Packers Movers in Jp Nagar" },
        { name: "JSS Packers Movers in Kadapa" },
        { name: "JSS Packers Movers in Kakinada" },
        { name: "JSS Packers Movers in Kalkaji" },
        { name: "JSS Packers Movers in Kalyan" },
        { name: "JSS Packers Movers in Kamothe" },
        { name: "JSS Packers Movers in Kanpur" },
        { name: "JSS Packers Movers in Karad" },
        { name: "JSS Packers Movers in Karur" },
        { name: "JSS Packers Movers in Kasaragod" },
        { name: "JSS Packers Movers in Katni" },
        { name: "JSS Packers Movers in Khar" },
        { name: "JSS Packers Movers in Khargar" },
        { name: "JSS Packers Movers in Khandwa" },
        { name: "JSS Packers Movers in Kharagpur" },
        { name: "JSS Packers Movers in Khurja" },
        { name: "JSS Packers Movers in Khushkhera" },
        { name: "JSS Packers Movers in Kishtwar" },
        { name: "JSS Packers Movers in Kochi" },
        { name: "JSS Packers Movers in Kolhapur" },
        { name: "JSS Packers Movers in Kolkata" },
        { name: "JSS Packers Movers in Kondapur" },
        { name: "JSS Packers Movers in Kondhwa" },
        { name: "JSS Packers Movers in Koparkhairane" },
        { name: "JSS Packers Movers in Koramangala" },
        { name: "JSS Packers Movers in Korba" },
        { name: "JSS Packers Movers in Kota" },
        { name: "JSS Packers Movers in Kothaguda" },
        { name: "JSS Packers Movers in Kothrud" },
        { name: "JSS Packers Movers in Kottayam" },
        { name: "JSS Packers Movers in Kphb" },
        { name: "JSS Packers Movers in Kukatpally" },
        { name: "JSS Packers Movers in Kulgam" },
        { name: "JSS Packers Movers in Kullu" },
        { name: "JSS Packers Movers in Kurla" },
        { name: "JSS Packers Movers in Kurnool" },
        { name: "JSS Packers Movers in Kurukshetra" },
        { name: "JSS Packers Movers in Lajpat Nagar" },
        { name: "JSS Packers Movers in Lb Nagar" },
        { name: "JSS Packers Movers in Leh Ladakh" },
        { name: "JSS Packers Movers in Lingampally" },
        { name: "JSS Packers Movers in Lonavala" },
        { name: "JSS Packers Movers in Lucknow" },
        { name: "JSS Packers Movers in Ludhiana" },
        { name: "JSS Packers Movers in Madhapur" },
        { name: "JSS Packers Movers in Madinaguda" },
        { name: "JSS Packers Movers in Madipakkam" },
        { name: "JSS Packers Movers in Madiwala" },
        { name: "JSS Packers Movers in Madurai" },
        { name: "JSS Packers Movers in Magarpatta City" },
        { name: "JSS Packers Movers in Mahadevapura" },
      ],
    },
    {
      title: "All India Services",
      services: [
        { name: "JSS Packers Movers in Mahim" },
        { name: "JSS Packers Movers in Mahipalpur" },
        { name: "JSS Packers Movers in Malad" },
        { name: "JSS Packers Movers in Malerkotla" },
        { name: "JSS Packers Movers in Malleswaram" },
        { name: "JSS Packers Movers in Malviya Nagar" },
        { name: "JSS Packers Movers in Manali" },
        { name: "JSS Packers Movers in Mandi" },
        { name: "JSS Packers Movers in Manesar" },
        { name: "JSS Packers Movers in Mangalore" },
        { name: "JSS Packers Movers in Manikonda" },
        { name: "JSS Packers Movers in Manipal" },
        { name: "JSS Packers Movers in Manpada" },
        { name: "JSS Packers Movers in Marathahalli" },
        { name: "JSS Packers Movers in Mathura" },
        { name: "JSS Packers Movers in Meerut" },
        { name: "JSS Packers Movers in Mehdipatnam" },
        { name: "JSS Packers Movers in Mehsana" },
        { name: "JSS Packers Movers in Mira Bhayandar" },
        { name: "JSS Packers Movers in Miyapur" },
        { name: "JSS Packers Movers in Moga" },
        { name: "JSS Packers Movers in Mohali" },
        { name: "JSS Packers Movers in Moradabad" },
        { name: "JSS Packers Movers in Morbi" },
        { name: "JSS Packers Movers in Morinda" },
        { name: "JSS Packers Movers in Muktasar" },
        { name: "JSS Packers Movers in Mulund" },
        { name: "JSS Packers Movers in Mumbai" },
        { name: "JSS Packers Movers in Mundra" },
        { name: "JSS Packers Movers in Muzaffarpur" },
        { name: "JSS Packers Movers in Mysore" },
        { name: "JSS Packers Movers in Nagercoil" },
        { name: "JSS Packers Movers in Nagpur" },
        { name: "JSS Packers Movers in Nagrota" },
        { name: "JSS Packers Movers in Nahan" },
        { name: "JSS Packers Movers in Nalagarh" },
        { name: "JSS Packers Movers in Nalasopara" },
        { name: "JSS Packers Movers in Nallagandla" },
        { name: "JSS Packers Movers in Nanakramguda" },
        { name: "JSS Packers Movers in Nangal" },
        { name: "JSS Packers Movers in Nanganallur" },
        { name: "JSS Packers Movers in Nashik" },
        { name: "JSS Packers Movers in Navi Mumbai" },
        { name: "JSS Packers Movers in Nellore" },
        { name: "JSS Packers Movers in Nerul" },
        { name: "JSS Packers Movers in Newtown" },
        { name: "JSS Packers Movers in Nigdi" },
        { name: "JSS Packers Movers in Nizamabad" },
        { name: "JSS Packers Movers in Nizampet" },
        { name: "JSS Packers Movers in Noida" },
        { name: "JSS Packers Movers in Old Bowenpally" },
        { name: "JSS Packers Movers in Ongole" },
        { name: "JSS Packers Movers in Palakkad" },
        { name: "JSS Packers Movers in Palampur" },
        { name: "JSS Packers Movers in Palam Vihar" },
        { name: "JSS Packers Movers in Palghar" },
        { name: "JSS Packers Movers in Panchkula" },
        { name: "JSS Packers Movers in Panipat" },
        { name: "JSS Packers Movers in Panjim" },
        { name: "JSS Packers Movers in Panvel" },
        { name: "JSS Packers Movers in Paonta Sahib" },
        { name: "JSS Packers Movers in Parwanoo" },
        { name: "JSS Packers Movers in Pashan" },
        { name: "JSS Packers Movers in Pathankot" },
        { name: "JSS Packers Movers in Patiala" },
        { name: "JSS Packers Movers in Patna" },
        { name: "JSS Packers Movers in Phagwara" },
        { name: "JSS Packers Movers in Pilani" },
        { name: "JSS Packers Movers in Pimple Saudagar" },
        { name: "JSS Packers Movers in Pimpri" },
        { name: "JSS Packers Movers in Pinjore" },
        { name: "JSS Packers Movers in Pitampura" },
        { name: "JSS Packers Movers in Pollachi" },
        { name: "JSS Packers Movers in Ponda" },
        { name: "JSS Packers Movers in Pondicherry" },
        { name: "JSS Packers Movers in Porbandar" },
        { name: "JSS Packers Movers in Powai" },
        { name: "JSS Packers Movers in Prayagraj" },
        { name: "JSS Packers Movers in Pudhucherry" },
        { name: "JSS Packers Movers in Pune" },
        { name: "JSS Packers Movers in Raigarh" },
        { name: "JSS Packers Movers in Raipur" },
        { name: "JSS Packers Movers in Rajahmundry" },
        { name: "JSS Packers Movers in Rajkot" },
        { name: "JSS Packers Movers in Rajnandgaon" },
        { name: "JSS Packers Movers in Ranchi" },
        { name: "JSS Packers Movers in Rangareddy" },
        { name: "JSS Packers Movers in Ratlam" },
        { name: "JSS Packers Movers in Ratnagiri" },
        { name: "JSS Packers Movers in Rewari" },
        { name: "JSS Packers Movers in Rohtak" },
        { name: "JSS Packers Movers in Rourkela" },
        { name: "JSS Packers Movers in Rudrapur" },
        { name: "JSS Packers Movers in Saharanpur" },
        { name: "JSS Packers Movers in Salem" },
        { name: "JSS Packers Movers in Sambalpur" },
        { name: "JSS Packers Movers in Samrala" },
        { name: "JSS Packers Movers in Sanath Nagar" },
        { name: "JSS Packers Movers in Sangrur" },
        { name: "JSS Packers Movers in Sarsuna" },
        { name: "JSS Packers Movers in Secunderabad" },
        { name: "JSS Packers Movers in Selaiyur" },
        { name: "JSS Packers Movers in Serilingampally" },
        { name: "JSS Packers Movers in Shahabad" },
        { name: "JSS Packers Movers in Shahjahanpur" },
        { name: "JSS Packers Movers in Shahkot" },
        { name: "JSS Packers Movers in Shirdi" },
        { name: "JSS Packers Movers in Shimla" },
        { name: "JSS Packers Movers in Shuklaganj" },
        { name: "JSS Packers Movers in Sikar" },
        { name: "JSS Packers Movers in Siliguri" },
        { name: "JSS Packers Movers in Sindhudurg" },
        { name: "JSS Packers Movers in Singrauli" },
        { name: "JSS Packers Movers in Sirsa" },
        { name: "JSS Packers Movers in Solan" },
        { name: "JSS Packers Movers in Solapur" },
        { name: "JSS Packers Movers in Surat" },
        { name: "JSS Packers Movers in Surendranagar" },
        { name: "JSS Packers Movers in Sushant Lok" },
        { name: "JSS Packers Movers in Tambaram" },
        { name: "JSS Packers Movers in Thane" },
        { name: "JSS Packers Movers in Thiruvananthapuram" },
        { name: "JSS Packers Movers in Thiruvallur" },
        { name: "JSS Packers Movers in Thrissur" },
        { name: "JSS Packers Movers in Tikamgarh" },
        { name: "JSS Packers Movers in Tirunelveli" },
        { name: "JSS Packers Movers in Tirupati" },
        { name: "JSS Packers Movers in Tirupur" },
        { name: "JSS Packers Movers in Tinsukia" },
        { name: "JSS Packers Movers in Tonk" },
        { name: "JSS Packers Movers in Trichy" },
        { name: "JSS Packers Movers in Trivandrum" },
        { name: "JSS Packers Movers in Tumkur" },
        { name: "JSS Packers Movers in Udaipur" },
        { name: "JSS Packers Movers in Ujjain" },
        { name: "JSS Packers Movers in Unjha" },
        { name: "JSS Packers Movers in Vadodara" },
        { name: "JSS Packers Movers in Valsad" },
        { name: "JSS Packers Movers in Vapi" },
        { name: "JSS Packers Movers in Varanasi" },
        { name: "JSS Packers Movers in Vasai" },
        { name: "JSS Packers Movers in Vashi" },
        { name: "JSS Packers Movers in Velachery" },
        { name: "JSS Packers Movers in Veraval" },
        { name: "JSS Packers Movers in Vijayawada" },
        { name: "JSS Packers Movers in Visakhapatnam" },
        { name: "JSS Packers Movers in Vithalwadi" },
        { name: "JSS Packers Movers in Wadi" },
        { name: "JSS Packers Movers in Wardha" },
        { name: "JSS Packers Movers in Yamunanagar" },
        { name: "JSS Packers Movers in Yavatmal" },
        { name: "JSS Packers Movers in Zirakpur" }
      ],
    },
  ];

  const contentData = [
    {
      image: sliderImg1,
      title: "Collaboration With Chat",
      mainText: "Better Design For Digital Products.",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more.",
    },
    {
      image: sliderImg2,
      title: "Excellent IT Services For Your Success",
      mainText: "Crafting Unique Digital .",
      description:
        "Unlock your business potential with our comprehensive IT services. We're here to support your journey to success.",
    },
    {
      image: sliderImg3,
      title: "Success Through Digital Innovation",
      mainText: "Delivering Quality Results.",
      description:
        "Achieve your business goals with our comprehensive and customized digital marketing services.",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % contentData.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []); // Empty dependency array to run only once on mount

  const { image } = contentData[currentIndex];
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);


  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(true);
    }, 3000); // 3 seconds delay

    return () => clearTimeout(timer); // Clean up the timer on component unmount
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };


  return (
    <>
      <PopupForm isOpen={isOpen} onClose={handleClose} />
      <div className="hero-main">
        <div className="position-absolute top-0 bottom-0 start-0 end-0 bg-gradient">
          <div className="inner-column" data-aos="zoom-in">
            <div className="sec-title light sec-title-main">
              <span className="sub-title">JSS Packers & Movers</span>
              <h2>Expert Packing Services for All Your Belongings</h2>

              <p>
                Welcome to JSS Packers and Movers, your trusted partner in
                seamless relocation services. Whether you're moving locally or
                internationally, we are here to ensure a stress-free and
                efficient moving experience.
              </p>
              <div>
                <Link
                  to="#"
                  onClick={openPopup}
                  className="theme-btn btn-style-one"
                >
                  <span className="btn-title custom-transition">Book Today 10% Off </span>
                </Link>
              </div>
            </div>
            <img className="air-flight" src={air} alt="" />
          </div>
        </div>

        <div
          className=" position-relative hero-main-image"
          data-aos="zoom-in"
          style={{
            backgroundImage: `url(${image})`,
            backgroundRepeat: "no-repeat",
            backgroundPositionX: "right",
            zIndex: "-1",
            margin: "40px 0",
            height: window.innerWidth <= 768 ? "400px" : "700px",
            transition: "background-image 1s ease-in-out",
            overflow:"hidden"
          }}
        ></div>
      </div>

      <section className="about-section-three">
        <div className="container">
          <div className="d-flex align-items-center " data-aos="fade-left">
            <div className="section-title mb-50">
              <span className="sec-title">WHAT WE DO</span>
              <h2>Explore Our Comprehensive Moving and Packing Services</h2>
            </div>
          </div>
          <div className="row">
            <CommonService />
          </div>
        </div>
      </section>

      <Common />
      <Process />
      <Choose />

      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="section-wrapper">
              <div
                className="section-title-wrapper animated fadeInUp"
                data-aos="zoom-in"
              >
                <div className="title-wrap">
                  <span className="subtitle-dots">
                    building a better future{" "}
                  </span>
                  <h2>Useful Tips and Advice for a Smooth Moving Experience</h2>
                </div>
                <div className="section-description"></div>
              </div>

              <div className="trust-score-wrapper">
                <p>Trust Score 4.5 (Based on 1,200 reviews)</p>
              </div>

              <div
                className="star-rating-wrapper animated fadeInUp"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                <div
                  className="elementor-star-rating"
                  title="4.5/5"
                  itemType="http://schema.org/Rating"
                  itemScope=""
                  itemProp="reviewRating"
                >
                  <span className="elementor-star-full">★</span>
                  <span className="elementor-star-full">★</span>
                  <span className="elementor-star-full">★</span>
                  <span className="elementor-star-full">★</span>
                  <span className="elementor-star">★</span>
                </div>
              </div>

              <div
                className="btn-box button-element mt-4"
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
              >
                <Link to="/contact" className="theme-btn btn-style-one">
                  <span className="btn-title">REQUEST A QUOTE </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="main-feature">
              <div className="feature-box-border">
                <div className="icon-feature">
                  <FaBoxesStacked />
                </div>
                <h4 className="title-feature">Packing Services</h4>
                <p className="content">
                  Our expert packing services ensure that your belongings are
                  packed securely and efficiently for safe transport to your new
                  destination
                </p>
              </div>

              <div className="feature-box">
                <div className="icon-feature">
                  <i className="bi bi-alarm-fill"></i>
                </div>
                <h4 className="title-feature">Residential Moving</h4>
                <p className="content">
                  Whether you're moving to a new home or apartment, our
                  residential moving services ensure a seamless transition.
                </p>
              </div>
            </div>
            <div className="second-feature">
              <div className="feature-box-border">
                <div className="icon-feature">
                  <FaDiamondTurnRight />
                </div>
                <h4 className="title-feature">Storage Solutions</h4>
                <p className="content">
                  Need temporary or long-term storage solutions? We offer secure
                  storage options to accommodate your needs, whether you're
                  between homes or downsizing.
                </p>
              </div>

              <div className="feature-box">
                <div className="icon-feature">
                  <FaTruckFront />
                </div>
                <h4 className="title-feature">Vehicle Transportation</h4>
                <p className="content">
                  Moving your vehicle? Our specialized vehicle transportation
                  services ensure safe and reliable transport to your new
                  location, nationwide
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-main-section bg-image-about">
        <img src={second} alt="" className="about-second-image" />
        <div className="container ">
          <div className="row">
            <div className="col-md-6">
              <div className="inner-column" data-aos="zoom-in">
                <div
                  className="sec-title light"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                >
                  <span className="sub-title text-light">EXPLORE SERVICES</span>
                  <h2 className="scrub-each text-split text-light">
                    Customer Satisfaction Is Our Top Priority
                  </h2>

                  <p className="text-light text-align-justify">
                    At JSS Packers and Movers, we are dedicated to ensuring your
                    complete satisfaction with every aspect of your move. From
                    the moment you contact us to the final delivery of your
                    belongings, we prioritize. We understand that every move is
                    unique. Our team works closely with you to tailor our
                    services to meet your specific needs and preferences. Our
                    experienced movers are trained to handle your belongings
                    with care and professionalism. We strive to make your moving
                    experience as smooth and stress-free as possible.
                  </p>
                </div>

                <div className="btn-box mt-5">
                  <Link to="/contact" className="theme-btn btn-style-one">
                    <span className="btn-title">REQUEST A QUOTE </span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="about-left"
                data-aos="fade-up"
                data-aos-anchor-placement="top-center"
              >
                <div className="row clearfix">
                  <div className=" about-section-images">
                    <img decoding="async" src={delivery4} alt="" />
                  </div>
                </div>
                <div
                  className="about-content-main"
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-center"
                >
                  <div className="about-service-main">
                    <FaUser />
                    <h6 className="">
                      Satisfied Clients
                      <br />
                      <span className="title-metre">350+</span>
                    </h6>
                  </div>

                  <div className="about-service-right">
                    <p>
                      <FaArrowCircleRight /> Worldwide Relocation Solutions
                    </p>
                    <p>
                      <FaArrowCircleRight /> Professional Moving Services
                    </p>
                    <p>
                      <FaArrowCircleRight /> Reliable Moving & Storage Experts
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="payment-main">
        <div className="container">

        <h5 className="mb-2">JSS Packers and Movers Charges</h5>
        <div className="table-responsive">
          <table className="table-payment">
            <thead>
              <tr>
                <th className="">Type of Shifting</th>
                <th className="">Up to 400 Km</th>
                <th className="">500 – 900 Km</th>
                <th className="">1000 – 1500 Km</th>
                <th className="">1600 – 2500 Km</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className=""><strong>1 BHK</strong> </td>
                <td className="">₹ 10,000 – 19,500</td>
                <td className="">₹ 11,500 - 22,500</td>
                <td className="">₹ 13,500 - 23,000</td>
                <td className="">₹ 16,200 - 24,000</td>
              </tr>
              <tr>
              <td className=""><strong>2 BHK</strong> </td>
                <td className="">₹ 11,500 - 23,000</td>
                <td className="">₹ 17,000 - 26,000</td>
                <td className="">₹ 18,000 - 28,000</td>
                <td className="">₹ 19,500 - 30,000</td>
              </tr>
              <tr>
              <td className=""><strong>3 BHK </strong></td>
                <td className="">₹ 15,400 - 26,500</td>
                <td className="">₹ 16,000 - 30,200</td>
                <td className="">₹ 20,000 - 31,500</td>
                <td className="">₹ 25,500 - 35,000</td>
              </tr>
              <tr>
              <td className=""><strong>Car Transport</strong> </td>
                <td className="">₹ 3,500 - 6,200</td>
                <td className="">₹ 5,000 - 7,000</td>
                <td className="">₹ 6,500 - 9,500</td>
                <td className="">₹ 8,500 - 10,000</td>
              </tr>
              <tr>
              <td className=""><strong>Bike Transport </strong></td>
                <td className="">₹ 2,200 - 4,300</td>
                <td className="">₹ 3,400 - 6,200</td>
                <td className="">₹ 4,200 - 7,200</td>
                <td className="">₹ 6,300 - 10,000</td>
              </tr>
              <tr>
              <td className=""><strong>Small Office</strong></td>
                <td className="">₹ 20,500 - 29,000</td>
                <td className="">₹ 24,000 - 31,000</td>
                <td className="">₹ 26,000 - 43,000</td>
                <td className="">₹ 31,000 - 47,000</td>
              </tr>
              <tr>
              <td className=""><strong>Medium Office</strong></td>
                <td className="">₹ 30,000 - 44,000</td>
                <td className="">₹ 27,000 - 63,000 </td>
                <td className="">₹ 35,000 - 62,000</td>
                <td className="">₹ 44,000 - 69,000</td>
              </tr>
            </tbody>
          </table>
          </div>


          <h5 className="mt-5 mb-2">JSS Packers and Movers Office Shifting Charges</h5>
          <div className="table-responsive">
          <table className="table-payment">
            <thead>
              <tr>
                <th className="">Office Types</th>
                <th className="">Up to 12 Km Move Price</th>
                <th className="">13 – 30 Km Move Price</th>
                <th className="">31+ Km Move Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
              <td className=""><strong>Micro Office 6 People Capacity </strong></td>
                <td className="">₹ 11,100 - 18,400</td>
                <td className="">₹ 13,250 - 22,450</td>
                <td className="">₹ 14,10 - 25,400</td>
              </tr>
              <tr>
              <td className=""><strong>Small Office 20 People Capacity</strong></td>
                <td className="">₹ 19,100 - 35,300</td>
                <td className="">₹ 23,150 - 40,250</td>
                <td className="">₹ 24,250 - 44,400</td>
              </tr>
              <tr>
                <td className=""><strong>Medium Office 50 People Capacity</strong></td>
                <td className="">₹ 45,100 - 70,300</td>
                <td className="">₹ 52,250 - 80,450</td>
                <td className="">₹ 60,400 - 90,350</td>
              </tr>
              <tr>
             <td className=""><strong>Standard Office 100 People Capacity</strong></td>
                <td className="">₹ 70,100 - 1,40,300</td>
                <td className="">₹ 80,150 - 1,50,200</td>
                <td className="">₹ 90,700 - 1,70,400</td>
              </tr>
              <tr>
             <td className=""><strong>Corporate Office More than 100</strong></td>
                <td className="">Contact for quotations</td>
                <td className="">Contact for quotations</td>
                <td className="">Contact for quotations</td>
              </tr>
            
            </tbody>
          </table>
          </div>


          <h5 className="mt-5 mb-2">JSS Bike / Car Transportation Charges</h5>
          <div className="table-responsive">
  <table className="table table-payment">
    <thead>
      <tr>
        <th className="">Move Type/Distance</th>
        <th className="">100 - 350 km</th>
        <th className="">350 - 750 km</th>
        <th className="">750 - 1200 km</th>
        <th className="">1200 - 1700 km</th>
        <th className="">1700 - 2300 km</th>
        <th className="">2300 - 3000 km</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td className=""><strong>Bike Transport</strong></td>
        <td>Rs. 2,550 - 5,780</td>
        <td>Rs. 3,450 - 6,900</td>
        <td>Rs. 4,430 - 8,445</td>
        <td>Rs. 6,440 - 11,770</td>
        <td>Rs. 7,470 - 12,560</td>
        <td>Rs. 8,165 - 14,455</td>
      </tr>
      <tr>
        <td className=""><strong>Car Transport</strong></td>
        <td>Rs. 5,770 - 10,960</td>
        <td>Rs. 7,440 - 13,740</td>
        <td>Rs. 9,390 - 16,450</td>
        <td>Rs. 11,660 - 19,470</td>
        <td>Rs. 13,260 - 22,575</td>
        <td>Rs. 15,265 - 25,700</td>
      </tr>
    </tbody>
  </table>
</div>

          
          <h5 className="mt-5 mb-2">JSS Packers and Movers Warehousing and Storage Rates</h5>
          <div className="table-responsive">
          <table className="table-payment">
            <thead>
              <tr>
                <th className="">Warehousing & Storage	Storage</th>
                <th className="">Storage Charges</th>
                <th className="">Approx. No. of Boxes</th>
              </tr>
            </thead>
            <tbody>
              <tr>
              <td className=""><strong>1 BHK House Hold Items</strong></td>
                <td>Rs. 3,100 per month</td>
                <td>20</td>
              </tr>
              <tr>
              <td className=""><strong>2 BHK House Hold Items</strong></td>
                <td>Rs. 4,100 per month</td>
                <td>30</td>
              </tr>
              <tr>
              <td className=""><strong>3 BHK House Hold Items</strong></td>
                <td>Rs. 5,300 per month</td>
                <td>40</td>
              </tr>
              <tr>
              <td className=""><strong>Small Box</strong></td>
                <td>Rs. 320 per month</td>
                <td>NA</td>
              </tr>
              <tr>
              <td className=""><strong>Medium Box</strong></td>
                <td>Rs. 540 per month</td>
                <td>NA</td>
              </tr>
              <tr>
              <td className=""><strong>Large Box</strong></td>
                <td>Rs. 600 per month</td>
                <td>NA</td>
              </tr>
              <tr>
              <td className=""><strong>Scooter/Bike Storage</strong></td>
                <td>Rs. 700 - 1,500 per month</td>
                <td>NA</td>
              </tr>
              <tr>
              <td className=""><strong>Car Storage (Hatchback)</strong></td>
                <td>Rs. 2,350 - 3,200 per month</td>
                <td>NA</td>
              </tr>
              <tr>
              <td className=""><strong>Car Storage (Sedan)</strong></td>
                <td>Rs. 3,050 - 4,200 per month</td>
                <td>NA</td>
              </tr>
              <tr>
              <td className=""><strong>Car Storage (SUV)</strong></td>
                <td>Rs. 4,000 - 4,200 per month</td>
                <td>NA</td>
              </tr>
            </tbody>
          </table>
          </div>
          <h5 className="mt-5 mb-2">JSS Packers and Movers Car Transport Charges</h5>
          <div className="table-responsive">
          <table className="table-payment">
            <thead>
              <tr>
                <th className="">Type of Shifting</th>
                <th className="">Upto 400 Km </th>
                <th className="">500 – 900 Km</th>
                <th className="">1000 – 1500 Km</th>
                <th className="">1,500 - 2,500 Km</th>
              </tr>
            </thead>
            <tbody>
              <tr>
              <td className=""><strong>Sedan</strong></td>
                <td className="">₹ 8,000 - 9,500</td>
                <td className="">₹ 11,500 - 12,500</td>
                <td className="">₹ 13,500 - 14,000</td>
                <td className="">₹ 16,200 - 24,000</td>
              </tr>
              <tr>
              <td className=""><strong>Hatchback</strong></td>
                <td className="">₹ 11,500 - 12,000</td>
                <td className="">₹ 11,000 - 13,000 </td>
                <td className="">₹ 12,000 - 15,000 </td>
                <td className="">₹ 14,500 - 23,000</td>
              </tr>
              <tr>
              <td className=""><strong>SUV</strong></td>
                <td className="">₹ 11,500 - 12,000</td>
                <td className="">₹ 11,000 - 14,000</td>
                <td className="">₹ 13,000 - 15,000</td>
                <td className=""> ₹ 16,000 - 25,000</td>
              </tr>
              <tr>
              <td className=""><strong>XUV</strong></td>
                <td className="">₹ 11,500 - 13,500</td>
                <td className="">₹ 11,000 - 12,200 </td>
                <td className="">₹ 14,000 - 15,500</td>
                <td className="">₹ 22,500 - 30,000</td>
              </tr>
            </tbody>
          </table>
          </div>
          
          <h5 className="mt-5 mb-2">JSS Packers and Movers Transport Charges</h5>
          <div className="table-responsive">
          <table className="table-payment">
            <thead>
              <tr>
                <th className=""> Vehicle Type</th>
                <th className="">50km To 100km</th>
                <th className="">100km To 160km</th>
                <th className="">200km To 300km</th>
              </tr>
            </thead>
            <tbody>
              <tr>
              <td className=""><strong>Pickup</strong></td>
                <td className="">Rs. 10,000 - 11,952</td>
                <td className="">Rs. 11,520 - 15,500</td>
                <td className="">Rs. 15,520 - 16,500</td>
              </tr>
              <tr>
              <td className=""><strong>Tata Ace Chota Hathi</strong></td>
                <td className="">Rs. 10,652 - 12,700</td>
                <td className="">Rs. 15,000 - 15,552</td>
                <td className="">Rs. 15,000 - 18,552</td>
              </tr>
              <tr>
              <td className=""><strong>Tata 407</strong></td>
                <td className="">Rs. 12,100 - 13,400</td>
                <td className="">Rs. 14,752 - 15,529</td>
                <td className="">Rs. 16,752 - 19,529</td>
              </tr>
              <tr>
              <td className=""><strong>14 Feet</strong></td>
                <td className=""> Rs. 13,752 - 13,800</td>
                <td className="">Rs. 15,552 - 16,552</td>
                <td className="">Rs. 17,552 - 19,552</td>
              </tr>
              <tr>
              <td className=""><strong>17 Feet</strong></td>
                <td className="">Rs. 14,752 - 15,800</td>
                <td className="">Rs. 16,552 - 17,552</td>
                <td className="">Rs. 18,552 - 20,552</td>
              </tr>
              <tr>
                <td className="">19 Feet </td>
                <td className="">Rs. 16,752 - 18,800</td>
                <td className="">Rs. 18,552 - 20,552</td>
                <td className="">Rs. 20,552 - 26,552</td>
              </tr>
            </tbody>
          </table>
</div>
          <h5 className="mt-5 mb-2">JSS Packers and Movers charges for House Shifting</h5>
          <div className="table-responsive">
          <table className="table-payment">
            <thead>
              <tr>
                <th className="">Price Breakup/Move Size</th>
                <th className="">1 BHK (Standard - Big)</th>
                <th className="">2 BHK (Standard - Big)</th>
                <th className="">3 BHK (Standard - Big)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
              <td className=""><strong>Packing Costs</strong></td>
                <td className="">Rs. 1,300 – 3,200</td>
                <td className="">Rs. 1,500 – 4,830</td>
                <td className="">Rs. 3,225 - 6,120</td>
              </tr>
              <tr>
              <td className=""><strong>Labour Charges</strong></td>
                <td className="">Rs. 1,100 – 2,800</td>
                <td className="">Rs. 1,420 – 4,230</td>
                <td className="">Rs. 2,755 - 5,405</td>
              </tr>
              <tr>
              <td className=""><strong>Transport Costs</strong></td>
                <td className="">Rs. 1,680 - 4,430</td>
                <td className="">Rs. 3,450 - 7,030</td>
                <td className="">Rs. 4,023 - 7,850</td>
              </tr>
              <tr>
              <td className=""><strong>Approx. Total Costs</strong></td>
                <td className="">Rs. 4,200 - 11,000</td>
                <td className="">Rs. 6,400 - 15,970</td>
                <td className="">Rs. 9,900 - 19,430</td>
              </tr>
            </tbody>
          </table>
</div>



          <h5 className="mt-5 mb-2">Approximate Packers and Movers Moving Charges</h5>
          <table className="table-payment">
  <thead>
    <tr>
      <th>BHK Size</th>
      <th>Distance</th>
      <th>Price Range</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td rowSpan="3"><strong>1 BHK</strong></td>
      <td>Up to 12 Km</td>
      <td>₹ 3,300 – 7,300</td>
    </tr>
    <tr>
      <td>13 – 30 Km</td>
      <td>₹ 4,200 – 8,300</td>
    </tr>
    <tr>
      <td>31+ Km</td>
      <td>₹ 6,100 – 9,300</td>
    </tr>
    <tr>
      <td rowSpan="3"><strong>2 BHK</strong></td>
      <td>Up to 12 Km</td>
      <td>₹ 4,300 – 8,300</td>
    </tr>
    <tr>
      <td>13 – 30 Km</td>
      <td>₹ 5,300 – 10,300</td>
    </tr>
    <tr>
      <td>31+ Km</td>
      <td>₹ 7,300 – 13,300</td>
    </tr>
    <tr>
      <td rowSpan="3"><strong>3 BHK</strong></td>
      <td>Up to 12 Km</td>
      <td>₹ 5,300 – 14,100</td>
    </tr>
    <tr>
      <td>13 – 30 Km</td>
      <td>₹ 6,400 – 16,200</td>
    </tr>
    <tr>
      <td>31+ Km</td>
      <td>₹ 8,600 – 18,300</td>
    </tr>
    <tr>
      <td rowSpan="3"><strong>4/5 BHK</strong></td>
      <td>Up to 12 Km</td>
      <td>₹ 8,100 – 16,300</td>
    </tr>
    <tr>
      <td>13 – 30 Km</td>
      <td>₹ 10,200 – 20,300</td>
    </tr>
    <tr>
      <td>31+ Km</td>
      <td>₹ 13,300 – 25,500</td>
    </tr>
  </tbody>
</table>

        </div>
      </div>




      <div className="container-fluid" style={{ backgroundColor: '#ffc800' }}>
      <div className="container">
        <div className="row h4 text-center">
          <div className="col-md-12">
            <h3 style={{ fontWeight: 600, marginTop: '30px', marginBottom: '20px' }}>
            Daily and Dedicated Packers and Movers Services to All India Metro Cities
            </h3>
          </div>
        </div>
        <div className="row">
      {servicesData.map((section, index) => (
        <div key={index} className="col-md-3 col-sm-4 col-xs-12">
          {/* <h4>
            <span className="clrb">{section.title}</span>
          </h4> */}
          <div className="navbg2 custom-scrollbar">
            <ul className="nav nav-list">
              {section.services.map((service, i) => (
                <li key={i}>{service.name}</li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
      </div>
    </div>

      <div className="container">
        <div className="row">
          <div
            className="d-flex align-items-center"
            data-aos="fade-left"
            data-aos-anchor="#example-anchor"
            data-aos-offset="500"
            data-aos-duration="500"
          >
            <div className="section-title mb-50">
              <span className="sec-title">WHAT WE DO</span>
              <h2>Useful Tips and Advice for a Smooth Moving Experience</h2>
            </div>
          </div>
          <div className="feature-box-wrapper">
            <div className="feature-box-inner">
              <figure className="feature-box-img">
                <img src={track} decoding="async" />
              </figure>
              <div className="feature-box-content">
                <div className="feature-box-number">1</div>
                <h4 className="feature-box-title">Shipment Tracking</h4>
                <div className="feature-box">
                  At JSS Packers and Movers, we understand how important it is
                  for you to know the status of your belongings during transit.
                </div>
              </div>
            </div>
            <div
              className="feature-box-inner"
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <figure className="feature-box-img">
                <img src={move2} alt="Shipment Tracking" decoding="async" />
              </figure>
              <div className="feature-box-content">
                <div className="feature-box-number">2</div>
                <h4 className="feature-box-title">Package & Storage</h4>
                <div className="feature-box">
                  At JSS Packers and Movers, we understand that the safety of
                  your belongings during transit is paramount. Our professional
                  packing services ensure that your items are securely packed
                </div>
              </div>
            </div>
            <div
              className="feature-box-inner"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="300"
              data-aos-offset="0"
            >
              <figure className="feature-box-img">
                <img src={move} alt="Shipment Tracking" decoding="async" />
              </figure>
              <div className="feature-box-content">
                <div className="feature-box-number">3</div>
                <h4 className="feature-box-title">International Shifting</h4>
                <div className="feature-box">
                  At JSS Packers and Movers, we specialize in international
                  shifting services designed to make your transition smooth and
                  hassle-free.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Testimonials />

      <FormMain />

      <Counter />

      <PopupForm isOpen={isPopupOpen} onClose={closePopup} />
    </>
  );
};

export default Home;
