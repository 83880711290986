import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import image1 from "../Assets/packaging1.webp"
import image2 from "../Assets/packaging2.webp"
import image3 from "../Assets/packaging3.webp"
import { FaAngleRight, FaEnvelope, FaPencilAlt, FaPhoneAlt, FaUserAlt } from 'react-icons/fa'
import Process from './Process';
import heavyhaul from "../Assets/nefab1.webp"

function Packaging() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
         <section className="page-title page-titles">
    <div className="container">
      <div className="banner-section">
       <div className=" page-content">
       <h1 className="title">International Shifting Service</h1>
        <ul className="page-breadcrumb">
         
          <li>Moving abroad is a complex process that involves more than just packing and transportation. JSS Packers and Movers provide comprehensive international shifting services, taking care of all the  Packers & Movers involved in an overseas move. From customs clearance to secure packing and shipping, their experienced team ensures a hassle-free international relocation, so you can focus on starting your new life abroad.</li>
        </ul>
       </div>
      <img src={heavyhaul} alt="" />
      </div>

    </div>
  </section> 

      <section className="single-service">
        <div className="container">
          <div className="row">
          <div className="col-md-4">
            <div className="service-page1-right">
              <div className="service-menu">
                <h6>Service Menu</h6>
                <ul>
                <Link to="/packers-movers-in-banglore"> <li>Packers & Movers <FaAngleRight /></li></Link> 
                <Link to="/office-shifting-in-banglore">  <li>Office Shifting <FaAngleRight /></li></Link> 
                <Link to="/home-shifting-in-banglore">  <li>Home Shifting<FaAngleRight /></li></Link> 
                <Link to="/storage-facilities-in-banglore">  <li>Storage Facilities<FaAngleRight /></li></Link> 
                <Link to="/bike-car-shifting-in-banglore">  <li>Bike & Car Shifting <FaAngleRight /></li></Link> 
                <Link to="/international-shifting-in-banglore">   <li>International Shifting <FaAngleRight /></li></Link> 
                 
                 
                </ul>
              </div>
              <div className="service-menu">
                <h6>Fill The Form</h6>
                <div className="form-fill">  <span>
                  <FaUserAlt /></span>
                  <input type="text" placeholder="Full Name" />
                  <span><FaEnvelope /></span>
                  <input type="email" placeholder="Your Email" />
                  <span><FaPhoneAlt /></span>
                  <input type="number" placeholder="Phone Number" />
                  <span><FaPencilAlt /></span>
                  <textarea name="" id="" cols="30" rows="7"></textarea>
                  <button>Send Now</button>
                </div>
              </div>
            </div> 
            </div>
            <div className="col-md-8">
              <div className="service-page1-left">
                <div className="page1-img-text">
                  <img src={image1} alt="" />
                  <h2>
                  International Shifting with JSS Packers and Movers in Bangalore </h2>
                  <p>
                  Relocating abroad can be an exciting and intimidating experience in a world where people are becoming more interconnected. Relocating to a new nation entails many difficulties and hurdles, regardless of the reason—job, school, or personal. Bangalore-based JSS Packers and Movers has made a name for itself as a reliable collaborator in arranging smooth overseas moves. This is a thorough look at how JSS Packers and Movers reduces stress associated with international moving.
                  </p>
                  <p>


                  With years of experience, JSS Packers and Movers is a specialist in international relocations. Their in-depth knowledge of the intricate  Packers & Movers of cross-border relocation is quite valuable. They precisely manage every part of the procedure, from paperwork to customs laws. Your stuff will be relocated to your new location safely and effectively thanks to our competence. </p>
                  
                  <h3> Safe Delivery with Expert Packaging Strategies </h3>
                  <img src={image2} alt="" />
                  <p>JSS Packers and Movers takes great satisfaction in their customer-focused methodology. They are aware that every motion is different and needs customised care. Their team of specialists is dedicated to delivering outstanding service, starting with the initial consultation and continuing through the final delivery. Informed about the status of their relocation, clients receive regular updates and have open lines of communication, which eases their fear and uncertainty.
                  </p>
                  <h5>Technological Integration</h5>
                  <p>
                  JSS Packers & Movers embraces technology and uses cutting-edge tracking technologies to keep an eye on the whereabouts and progress of shipments in real time. Because of this openness, customers may remain informed and confident in the security of their possessions. Their web portals also make it simple to obtain quotes, documents, and other relevant data, which streamlines the procedure in its entirety.</p>

                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="page1-left-text">
                      <h6>Sustainable Practices</h6>
                      <p>
                      Sustainable practices are important to JSS Packers and Movers because we live in an ecologically concerned world. To lessen their carbon impact, they optimise their  Packers & Movers and employ environmentally friendly packing materials. Their dedication to sustainability is a reflection of their duty to future generations as well as an advantage to the environment.</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="page1-right-pic">
                      <img src={image3} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </section>

      <Process />
    </>
  );
}

export default Packaging;


