import React from 'react'
import process1 from "../Assets/process1-1.webp";
import process2 from "../Assets/book.webp"
import process3 from "../Assets/truck_2953972.webp";
import process4 from "../Assets/delivery-truck_566712.webp";


const Process = () => {
  
  return (
    <>
     <section className=" pt-0 bg bg-about" data-aos="fade-down">
        <div className="icon-plane-6 bounce-y"></div>
        <div className="container">
          <div className="sec-about seconds-title">
            <span className="sub-icon"></span>
            <span className="sub-title">
             Our
            </span>
            <h2 className="words-slide-up text-split">
              Working Process 
            </h2>
          </div>
          <div className="outer-box">
            <div className="row">
              <div className="icon-line"></div>
              <div
                className="process-block-img process-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp animated"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                <div className="inner-box">
                  <div className="">
                    <div className="icon-box-about">
                      <figure className="image-about">
                        <img src={process1} alt="" />
                      </figure>
                      <span className="count">1</span>
                    </div>
                  </div>
                  <div className="content-box">
                    <h6 className="title">Request A Quote</h6>
                    
                  </div>
                </div>
              </div>
              <div
                className="process-block-image process-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp animated"
                data-wow-delay="300ms"
                style={{
                  visibility: "visible",
                  animationDelay: "300ms",
                  animationName: "fadeInUp",
                }}
              >
                <div className="inner-box">
                <div className="">
                    <div className="icon-box-about">
                    <figure className="image-about">
                      <img src={process2} alt="" />
                    </figure>
                    <span className="count">2</span>
                  </div>
                  </div>
                  <div className="content-box">
                    <h6 className="title">Select Service Type</h6>
                   
                  </div>
                </div>
              </div>
              <div
                className="process-block-img process-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp animated"
                data-wow-delay="600ms"
                style={{
                  visibility: "visible",
                  animationDelay: "600ms",
                  animationName: "fadeInUp",
                }}
              >
                <div className="inner-box">
                <div className="">
                    <div className="icon-box-about">
                    <figure className="image-about">
                      <img src={process3} alt="" />
                    </figure>
                    <span className="count">3</span>
                  </div>
                  </div>
                  <div className="content-box">
                    <h6 className="title">Port Delivery Available</h6>
                   
                  </div>
                </div>
              </div>
              <div
                className="process-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp animated"
                data-wow-delay="900ms"
                style={{
                  visibility: "visible",
                  animationDelay: "900ms",
                  animationName: "fadeInUp",
                }}
              >
                <div className="inner-box">
                <div className="">
                    <div className="icon-box-about">
                    <figure className="image-about">
                      <img src={process4} alt="" />
                    </figure>
                    <span className="count">4</span>
                  </div>
                  </div>
                  <div className="content-box">
                    <h6 className="title">Deliver Shipping</h6>
                   
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </section> 
    </>
  )
}

export default Process
