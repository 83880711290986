import React, { useState, useEffect, useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

import { FaEnvelope, FaLocationArrow, FaPhoneAlt } from "react-icons/fa";
import FormTwo from "./FormTwo";
const FormMain = () => {
  const sliderRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0); // Go to the first slide when the component mounts
    }
  }, []);
  return (
    <>
      <section className="contact-details">
        <div className="overlay-second"></div>

        <div className="container ">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6 ">
              <div
                className="contact-details__right"
                data-aos="fade-up"
                data-aos-anchor-placement="center-center"
              >
                <div className="sec-title">
                  <span className="sub-title">Need any help?</span>
                  <h2>Contact Us for More Information and Assistance</h2>
                  <div className="text">
                    Have additional questions or need further assistance? Get in
                    touch with our friendly team. We're here to help! Find our
                    office locations, phone numbers, and email addresses for
                    convenient communication.
                  </div>
                </div>
                <ul className="list-unstyled contact-details__info">
                  <li>
                    <div className="icon">
                      <span className="lnr-icon-phone-plus">
                        <FaPhoneAlt />
                      </span>
                    </div>
                    <div className="text">
                      <h6 className="mb-1">Have any question?</h6>
                      <Link to="tel:9686825980">
                        <span>Free</span>   +91-96868-25980
                      </Link>
                      <br />
                      <Link to="tel:9686830180">
                        <span>Free</span>   +91-96868-30180
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="lnr-icon-envelope1">
                        <FaEnvelope />
                      </span>
                    </div>
                   


                    <div className="text">
                      <h6 className="mb-1">Write email</h6>
                      <Link to="mailto:info@jsspackersmovers.inm">
                      info@jsspackersmovers.in
                      </Link>{" "}  <br />
                      <Link to="mailto:invoice@jsspackersmovers.in">
                      invoice@jsspackersmovers.in
                      </Link>{" "}  <br />
                      <Link to="mailto:support@jsspackersmovers.in">
                      support@jsspackersmovers.in
                      </Link>{" "}
                    
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="lnr-icon-location">
                        <FaLocationArrow />
                      </span>
                    </div>
                    <div className="text">
                      <h6 className="mb-1">Visit anytime</h6>
                      <span>
                      B-20 , 4Th Main Road Dduttl Suburb, Near Ks Automobiles, <br /> Narasimha Layout, Yeswanthpur, Bengaluru, Karnataka 560022 
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 ">
             <FormTwo  />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FormMain;
