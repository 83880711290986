import React, { useEffect } from "react";
import { FaAngleRight, FaArrowCircleRight, FaUser } from "react-icons/fa";
import Testimonials from "./Testimonial";
import air from "../Assets/request-3.webp";
import book from "../Assets/request-4.webp";
import delivery4 from "../Assets/cost.webp";
import delivery3 from "../Assets/delivery-time.webp";
import time2 from "../Assets/quote-img-new-1.webp";
import time3 from "../Assets/time-3.webp";
import time1 from "../Assets/quote-img-new-2.webp";
import second from "../Assets/request-one_pattern.webp";
import { Link } from "react-router-dom";
const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="page-title">
        <div className="container">
          <div className=" text-center-padding">
            <h1 className="title">About Us</h1>
            <ul className="page-breadcrumb-two">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <FaAngleRight />
              </li>
              <li>About Us</li>
            </ul>
          </div>
        </div>
      </section>

      <div className="about-main-section ">
        <img src={second} alt="" className="about-second-image" />
        <div className="container ">
          <div className="row">
            <div className="col-md-6">
              <div className="about-left">
                <div className="row clearfix">
                  <div className="column col-lg-5 col-md-5 col-sm-6">
                    <div className="" data-aos="zoom-in-down">
                      <img src={time1} alt="" />
                    </div>
                  </div>
                  <div className="column col-lg-7 col-md-7 col-sm-6">
                    <div className=" about-section-images" data-aos="zoom-in-left">
                      <img decoding="async" src={time2} alt="" />
                    </div>
                  </div>
                </div>

                <div className="about-bottom">
                  <img src={time3} alt=""  data-aos="zoom-in-right"/>

                  <div className="about-title-main">
                    <img src={delivery3} alt="" />
                    <h6 className="">
                      Working Exprience
                      <br />
                      <span className="title-metre"> 15 Years</span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="inner-column" data-aos="zoom-in">
                <div className="sec-title light">
                  <span className="sub-title ">ABOUT US</span>
                  <h2 className="scrub-each text-split">
                  Comprehensive Guide to Our Commercial Moving Solutions

                  </h2>

                  <p>
                  At JSS Packers and Movers, we pride ourselves on our commitment to excellence and customer satisfaction. With years of experience in the industry, we have established ourselves as a leader in reliable and professional moving services.
                  </p>
                </div>

                <div className="row g-0">
                  <div className="choose-block-two col-lg-6 col-md-6 col-sm-6">
                    <div className="inner-box">
                      <div className="title-main">
                        <h6 className="">
                        Delivered Products <br />
                          <span className="title-metre"> 5.15m</span>
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className="choose-block-two col-lg-6 col-md-6 col-sm-6">
                    <div className="inner-box" data-aos="zoom-in-up">
                      <div className="title-main">
                        <h6 className="">
                          Clients Worldwide <br />
                          <span className="title-metre">3.15m </span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="btn-box mt-5">
                  <Link to="/contact" className="theme-btn btn-style-one">
                    <span className="btn-title">REQUEST A QUOTE </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

   

      <div className="about-main-section bg-image-about">
        <img src={second} alt="" className="about-second-image" data-aos="zoom-out-up" />
        <div className="container ">
          <div className="row">
            <div className="col-md-6">
              <div className="inner-column" data-aos="zoom-in-right">
                <div className="sec-title light">
                  <span className="sub-title text-light">EXPLORE SERVICES</span>
                  <h2 className="scrub-each text-split text-light">
                  Learn More About JSS Packers and Movers
                  </h2>

                  <p className="text-light text-align-justify ">
                  At JSS Packers and Movers, we pride ourselves on our commitment to excellence and customer satisfaction. With years of experience in the industry, we have established ourselves as a leader in reliable and professional moving services. Founded with a passion for providing top-notch moving solutions, JSS Packers and Movers began with a vision to redefine the moving experience.
                  </p>
                </div>

                <div className="btn-box mt-5">
                  <Link to="/contact" className="theme-btn btn-style-one">
                    <span className="btn-title">REQUEST A QUOTE </span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="about-left">
                <div className="row clearfix">
                  <div className=" about-section-images">
                    <img decoding="async" src={delivery4} alt=""  data-aos="zoom-out"/>
                  </div>
                </div>
                <div className="about-content-main">
                  <div className="about-service-main">
                    <FaUser />
                    <h6 className="">
                      Satisfied Clients
                      <br />
                      <span className="title-metre">350+</span>
                    </h6>
                  </div>

                  <div className="about-service-right">
                    <p>
                      <FaArrowCircleRight /> Worldwide Relocation Solutions
                    </p>
                    <p>
                      <FaArrowCircleRight /> Professional Moving Services
                    </p>
                    <p>
                      <FaArrowCircleRight /> Reliable Moving & Storage Experts
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-main-section ">
        <img src={second} alt="" className="about-second-image" />
        <div className="container ">
          <div className="row">
            <div className="col-md-6">
              <div className="about-left">
                <div className="row clearfix">
                  <div className=" ">
                    <img
                      decoding="async"
                      src={time2}
                      alt=""
                      className=" about-section-middle"
                    />

                    <img src={air} alt="" className="about-section-left" />
                    <img src={book} alt="" className="about-section-right" />
                    <div className="section-bottom-left">
                  <img src={delivery3} alt="" />
                  <h6 className="">
                  Trusted By
                    <br />
                    <span className=""> Our Clients</span>
                  </h6>
                </div>
                  </div>
                </div>

               
              </div>
            </div>

            <div className="col-md-6">
              <div className="inner-about-main" data-aos="zoom-in">
                <div className="sec-title light">
                  <span className="sub-title ">REQUEST A QUOTE</span>
                  <h2 className="scrub-each text-split">
                  Mission and Vision
                  </h2>

                  <p>
                  Our mission is to deliver exceptional moving services tailored to meet the unique needs of each client. We strive to exceed expectations through reliability, integrity, and dedication to quality.
                  </p>
                </div>

                <div className="row g-0">
                  <div className="choose-block-two col-lg-6 col-md-6 col-sm-6">
                    <div className="inner-box">
                      <div className="title-main">
                        <h6 className="">
                          <span className="title-metre">Reliable  <br /></span>
                          Office Shifting Service
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className="choose-block-two col-lg-6 col-md-6 col-sm-6">
                    <div className="inner-box" data-aos="zoom-in-up">
                      <div className="title-main">
                      <h6 className="">
                          <span className="title-metre">Reliable  <br /></span>
                          Storage Facilities
                          Services
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="btn-box mt-5">
                  <Link to="/contact" className="theme-btn btn-style-one">
                    <span className="btn-title">REQUEST A QUOTE </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  


      <Testimonials />
    </>
  );
};

export default About;
