import React, { useEffect } from 'react'
import FormMain from './Form'
import { Link } from 'react-router-dom';
import { FaLocationPin } from 'react-icons/fa6';
import { FaPhoneAlt } from 'react-icons/fa';


const Contact = () => {
  const locations = [
    {
      country: 'Karnataka',
      address: 'B-20 , 4Th Main Road Dduttl Suburb, Near Ks Automobiles, Narasimha Layout, Yeswanthpur, Bengaluru, Karnataka 560022',
      email: 'info@jsspackersmovers.in',
      phone: '+91-96868-25980',
      imgSrc: '/static/media/india-img.b3fce4753f40ee813acd.webp'
    },
    {
      country: 'Haryana ',
      address: 'Plot No.122, Gali Number 5, near Ak Rk Tower, Dharam Colony, Palam Vihar Extension, Gurugram, Haryana 122017',
      email: 'invoice@jsspackersmovers.in',
      phone: '+91-96868-30180',
      imgSrc: '/static/media/italy.8bb6bdf322bcd0e29541.webp'
    },
    {
      country: 'Maharashtra',
      address: 'no.8, Tiwari Compound, Jogeshwari - Vikhroli Link Rd, Milind Nagar, Krishna Nagar, Powai, Mumbai, Maharashtra 400072',
      email: 'support@jsspackersmovers.in',
      phone: '+91-96868-25980',
      imgSrc: '/static/media/paris.c1a134863a3b2e776e1d.webp'
    }
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <FormMain />

      <div className="container my-5">
    <div className="row justify-content-center">
      {locations.map((location, index) => (
        <div className="col-md-4 " key={index}>
          <div className="card border-primary">
            <div className="card-body text-center">
              {/* <img src={location.imgSrc} alt={location.country} className="img-fluid mx-auto d-block" /> */}
              <h4 className="card-title mt-3">{location.country}</h4>
              <h6 className="card-subtitle mb-2 text-muted">Registered Office</h6>
              <p className="card-text">{location.address}</p>
              <ul className="list-unstyled">
                <li>
                  <Link to={`mailto:${location.email}`} className="d-flex gap-3 align-items-center text-black">
                   <FaPhoneAlt /> 
                    {location.email}
                  </Link>
                </li>
                <li>
                  <Link to={`tel:${location.phone}`} className="d-flex gap-3 mt-3 align-items-center text-black">
                    <FaLocationPin />
                    {location.phone}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
     
      <div className="map-container">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.202734494326!2d77.53213007484227!3d13.022757987297325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3d12387fd989%3A0xcf2b4676cdcc4e6d!2sb%20a%2C%20A-20%2C%20Narasimha%20Layout%2C%20Nandini%20Layout%2C%20Bengaluru%2C%20Karnataka%20560022!5e0!3m2!1sen!2sin!4v1723634313932!5m2!1sen!2sin"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
    </>
  )
}

export default Contact
