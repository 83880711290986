import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import image1 from "../Assets/warehouse1.webp"
import image2 from "../Assets/96930485.webp"
import image3 from "../Assets/warehouse2.webp"
import { FaAngleRight, FaEnvelope, FaPencilAlt, FaPhoneAlt, FaUserAlt } from 'react-icons/fa'
import Process from './Process';
import heavyhaul from "../Assets/wareba.webp"


const Warehousing =()=> {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
<>
<section className="page-title page-titles">
    <div className="container">
      <div className="banner-section">
       <div className=" page-content">
       <h1 className="title">Storage Facilities Service</h1>
        <ul className="page-breadcrumb">
         
          <li>Whether you need temporary storage during a move or a long-term storage solution, JSS Packers and Movers offer secure and convenient storage services in Bangalore. Their state-of-the-art storage facilities are equipped with advanced security systems to keep your belongings safe. With flexible storage options, you can store your items for as long as you need, knowing they are in good hands.</li>
        </ul>
       </div>
      <img src={heavyhaul} alt="" />
      </div>

    </div>
  </section> 

    <section className="single-service">
      <div className="container">
        <div className="row">
        <div className="col-md-4">
          <div className="service-page1-right">
              <div className="service-menu">
                <h6>Service Menu</h6>
                <ul>
                <Link to="/packers-movers-in-banglore"> <li>Packers & Movers <FaAngleRight /></li></Link> 
                <Link to="/office-shifting-in-banglore">  <li>Office Shifting <FaAngleRight /></li></Link> 
                <Link to="/home-shifting-in-banglore">  <li>Home Shifting<FaAngleRight /></li></Link> 
                <Link to="/storage-facilities-in-banglore">  <li>Storage Facilities<FaAngleRight /></li></Link> 
                <Link to="/bike-car-shifting-in-banglore">  <li>Bike & Car Shifting <FaAngleRight /></li></Link> 
                <Link to="/international-shifting-in-banglore">   <li>International Shifting <FaAngleRight /></li></Link> 
                 
                </ul>
              </div>
              <div className="service-menu">
                <h6>Fill The Form</h6>
                <div className="form-fill">  <span>
                  <FaUserAlt /></span>
                  <input type="text" placeholder="Full Name" />
                  <span><FaEnvelope /></span>
                  <input type="email" placeholder="Your Email" />
                  <span><FaPhoneAlt /></span>
                  <input type="number" placeholder="Phone Number" />
                  <span><FaPencilAlt /></span>
                  <textarea name="" id="" cols="30" rows="7"></textarea>
                  <button>Send Now</button>
                </div>
              </div>
            </div> 
          </div>
          <div className="col-md-8">
            <div className="service-page1-left">
              <div className="page1-img-text">
                <img src={image1} alt="" />
                <h2>
                Comprehensive Storage Facilities Service by JSS Packers and Movers in Bangalore</h2>
              <p>It might be difficult to locate a trustworthy and secure storage option in Bangalore, a busy city where people are always on the go. Reputable in the moving business, JSS Packers and Movers, provides outstanding storage facilities service for a variety of demands, including household goods, commercial inventory, and personal possessions. This is a detailed look at the excellent storage services that JSS Packers and Movers in Bangalore offers.
                </p>
                <p>Security is the first priority of JSS Packers and Movers. Modern security systems, such as access control systems, fire alarms, and round-the-clock CCTV surveillance, are installed in the storage facilities. This guarantees that your possessions are protected from fire, theft, and unauthorised entry.</p>
                
                <h3>Flexible Storage Plans </h3>
                <img src={image2} alt="" />
                <p>Recognising that every customer has distinct requirements, JSS Packers and Movers provides customisable storage options. There are options made to accommodate different timeframes and price ranges, whether you require long-term storage for company goods or short-term storage while renovating your house. Customers can simply increase or decrease the size of their storage containers as needed, making the process hassle-free.</p>
                <h5>Professional Packing and Moving Services </h5>
                <p>One of the main features of JSS Packers and Movers' storage services is convenience. The storage facilities are arranged so that customers may easily access them around Bangalore. A well-kept infrastructure makes the process of loading and unloading cargo easy. In addition, consumers can access their items whenever it's convenient for them because the facilities are available seven days a week. </p>

              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="page1-left-text">
                    <h6>Affordable and Transparent Pricing</h6>
                    <p>
                    JSS Packers and Movers is committed to you good value for your money. The cost of the storage services is reasonable, and there are no additional fees because of clear billing procedures. Customers may make educated judgements because they are provided with comprehensive quotes up front. Periodically, there are also special discounts and promotions available, which further lowers the cost of the service.</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="page1-right-pic">
                    <img src={image3} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </section>


    <Process />
    </>
  );
}

export default Warehousing;


