import React, { useEffect } from "react";
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import image1 from "../Assets/privacy-policy.webp";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="page-title">
        <div className="container">
          <div className="  text-center-padding">
            <h1 className="title">Privacy</h1>
            <ul className="page-breadcrumb-two">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <FaAngleRight />
              </li>
              <li>Privacy & Policy</li>
            </ul>
          </div>
        </div>
      </section>

        <div className="container">
      <div className="privacy">
        <div className="privacy-content">
        <h2>Privacy & Policy</h2>
        <p>
        At JSS Packers and Movers, we are dedicated to safeguarding your personal information. We collect data such as your name, contact details, and service requirements to efficiently manage and fulfill your moving needs. This information is used solely to provide and improve our services, process payments, and communicate with you. We implement robust security measures to protect your data and do not share it with third parties, except as required by law or with trusted service providers who assist us in delivering our services. You have the right to access, update, or delete your information, and you can opt-out of marketing communications at any time. For any questions or concerns about our privacy practices, please contact us directly.


        </p>
        </div>
        <img src={image1} alt="" />
        
      </div>
      </div>
    </>
  );
};

export default Privacy;
