import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaMapPin,
  FaPhoneAlt,
  FaTwitter,
} from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";

const TopBar = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className="header-style-four " data-aos="fade-down">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="header-left-content">
              <ul>
                <li>
                  <Link to="#">
                    <FaMapPin />
              B-20  Narasimha Layout, Bengaluru 560022
                  </Link>
                </li>
                <li className="main-li">
                  <Link to="#">
                    <FaEnvelope />
                    <span
                      className=""
                      data-cfemail="87efe2ebebe8c7f7f5e2f1e8fda9e4e8ea"
                    >
                      info@jsspackersmovers.in
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="header-right-content">
              <ul className="additional-link">
                {/* <li>
                  <Link to="/payment">Online Payment</Link>
                </li> */}
               
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
              <ul className="social-links">
                <li>
                  <Link to="https://www.facebook.com/">
                    <FaFacebookF />
                  </Link>
                </li>
                <li>
                  <Link to="https://twitter.com/">
                    <FaTwitter />
                  </Link>
                </li>
                <li>
                  <Link to="https://www.instagram.com/">
                    <FaInstagram />
                  </Link>
                </li>
                <li>
                  <Link to="https://www.linkedin.com/">
                    <FaLinkedinIn />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
