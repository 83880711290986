import React, { useState } from "react";
import { FaEnvelope, FaPhoneAlt, FaUser } from "react-icons/fa";
import { FaMapLocation } from "react-icons/fa6";

const FormTwo = ({ onClose}) => {
  const [formData, setFormData] = useState({
    S_name: "",
    S_email: "",
    S_phone: "",
    Location_form: "",
    Location_to: "",
    userEmailsir:"info@jsspackersmovers.in"
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const urlEncodedData = new URLSearchParams();

    for (const [key, value] of Object.entries(formData)) {
      urlEncodedData.append(key, value);
    }

    try {
      const response = await fetch("https://sendingmail-6znv.onrender.com/sendmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: urlEncodedData.toString()
      });

      if (response.ok) {
        alert("Form submitted successfully!");
        // Optionally, reset the form fields
        onClose();
        setFormData({
          S_name: "",
          S_email: "",
          S_phone: "",
          Location_form: "",
          Location_to: ""
        });
      } else {
        alert("Failed to submit the form. Please try again.");
      }
    } catch (error) {
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <div className="contact-details__left " data-aos="fade-up">
      <div className="overlay-left"></div>
      <div className="sec-title">
        <h2>Ask Question?</h2>
        <span className="sub-title">
          Fill up form below, our team will get back soon
        </span>
      </div>

      <form id="contact_form" name="contact_form" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-sm-12">
            <div className="mb-3 d-relative">
              <FaUser className="icon-form" />
              <input
                name="S_name"
                className="form-control"
                type="text"
                placeholder="Enter Name"
                value={formData.S_name}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="mb-3 d-relative">
              <FaEnvelope className="icon-form" />
              <input
                name="S_email"
                className="form-control required email"
                type="email"
                placeholder="Enter Email"
                value={formData.S_email}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-3 d-relative">
              <FaPhoneAlt className="icon-form" />
              <input
                name="S_phone"
                className="form-control"
                type="text"
                placeholder="Enter Phone"
                value={formData.S_phone}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="mb-3 d-relative">
          <div className="row">
            <div className="col-sm-6">
              <div className="mb-3 d-relative">
                <FaMapLocation className="icon-form" />
                <input
                  name="Location_form"
                  className="form-control required"
                  type="text"
                  placeholder="Location from"
                  value={formData.Location_form}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-3 d-relative">
                <FaMapLocation className="icon-form" />
                <input
                  name="Location_to"
                  className="form-control"
                  type="text"
                  placeholder="Location to"
                  value={formData.Location_to}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-5">
         
          <button
            type="submit"
            className="theme-btn btn-style-one tn-two"
            data-loading-text="Please wait..."
          >
            <span className="btn-title">Send Now</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormTwo;
