import React from "react";

import delivery from "../Assets/tracking.webp";
import shield from "../Assets/shield.webp";
import { Link } from "react-router-dom";
import time1 from "../Assets/time1.webp"
import time2 from "../Assets/time-2.webp"
import time3 from "../Assets/time-3.webp"
import "aos/dist/aos.css";
const Common = () => {
  return (
    <>
    <div className="bg-image-about text-white bg-margin" >
    <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="row clearfix">
              <div className="column col-lg-6 col-md-6 col-sm-6">
                <div className="" data-aos="zoom-in-up">
                  <img
                   className="bg-images"
                    src={time1}
                    alt=""
                    style={{borderRadius:"10px"}}
                  />
                </div>
              </div>
              <div className="column col-lg-6 col-md-6 col-sm-6">
                <div className="time-one_image now-in-view" data-aos="zoom-in">
                  {/* <img
                    decoding="async"
                    src={time2}
                    alt=""
                  /> */}
                </div>
                <div className="time-one_image now-in-view" data-aos="zoom-in-down">
                  {/* <img
                    decoding="async"
                    src={time3}
                    alt=""
                  /> */}
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="inner-column" data-aos="zoom-in">
              <div className="sec-title light">
                <span className="sub-title ">Jss Packers & Movers </span>
                <h2 className="scrub-each text-split">
                Comprehensive Guide to Our Commercial Moving Solutions
                </h2>
              
                <p>At JSS Packers and Movers, we pride ourselves on our commitment to excellence and customer satisfaction. With years of experience in the industry, we have established ourselves as a leader in reliable and professional moving services.</p>
              </div>
              
              <div className="row g-0">
                <div className="choose-block-two col-lg-6 col-md-6 col-sm-6">
                  <div className="inner-box">
                    <img src={shield} alt="" />
                    <div className="title-main">
                      <h6 className="">
                      Delivered Products <br />
                     <span className="title-metre"> 5.15m</span>
                      </h6>
                    </div>
                  </div>
                </div>

                <div className="choose-block-two col-lg-6 col-md-6 col-sm-6">
                  <div className="inner-box" data-aos="zoom-in-up">
                    <img src={delivery} alt="" data-aos="zoom-in-up" />
                    <div className="title-main">
                      <h6 className="">
                      Clients Worldwide <br />
                      <span className="title-metre">
3.15m </span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn-box mt-5">
              <Link to="/contact" className="theme-btn btn-style-one">
                <span className="btn-title">REQUEST A QUOTE </span>
              </Link>
              
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Common;
