import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import image1 from "../Assets/2150895675.webp"
import image2 from "../Assets/1200.webp"
import image3 from "../Assets/air.webp"
import heavyhaul from "../Assets/Air-Freight-101.webp"

import { FaAngleRight, FaEnvelope, FaPencilAlt, FaPhoneAlt, FaUserAlt } from 'react-icons/fa'
import Process from './Process';


function AirFreight() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="page-title page-titles">
    <div className="container">
      <div className="banner-section">
       <div className=" page-content">
       <h1 className="title">Office Shifting Service</h1>
        <ul className="page-breadcrumb">
         
          <li>Relocating an office requires meticulous planning and coordination to minimize downtime and disruption to business operations. JSS Packers and Movers excel in office shifting, providing efficient and seamless services tailored to the unique needs of each business. Their expert team manages everything from packing office equipment and furniture to setting up the new office space, ensuring a smooth transition and quick resumption of work.</li>
        </ul>
       </div>
      <img src={heavyhaul} alt="" />
      </div>

    </div>
  </section> 

      <section className="single-service">
        <div className="container">
          <div className="row">
          <div className="col-md-4">
            <div className="service-page1-right">
              <div className="service-menu">
                <h6>Service Menu</h6>
                <ul>
                <Link to="/packers-movers-in-banglore"> <li>Packers & Movers <FaAngleRight /></li></Link> 
                <Link to="/office-shifting-in-banglore">  <li>Office Shifting <FaAngleRight /></li></Link> 
                <Link to="/home-shifting-in-banglore">  <li>Home Shifting<FaAngleRight /></li></Link> 
                <Link to="/storage-facilities-in-banglore">  <li>Storage Facilities<FaAngleRight /></li></Link> 
                <Link to="/bike-car-shifting-in-banglore">  <li>Bike & Car Shifting <FaAngleRight /></li></Link> 
                <Link to="/international-shifting-in-banglore">   <li>International Shifting <FaAngleRight /></li></Link> 
                 
                 
                </ul>
              </div>
              <div className="service-menu">
                <h6>Fill The Form</h6>
                <div className="form-fill">  <span>
                  <FaUserAlt /></span>
                  <input type="text" placeholder="Full Name" />
                  <span><FaEnvelope /></span>
                  <input type="email" placeholder="Your Email" />
                  <span><FaPhoneAlt /></span>
                  <input type="number" placeholder="Phone Number" />
                  <span><FaPencilAlt /></span>
                  <textarea name="" id="" cols="30" rows="7"></textarea>
                  <button>Send Now</button>
                </div>
              </div>
            </div> 
            </div>
            <div className="col-md-8">
              <div className="service-page1-left">
                <div className="page1-img-text">
                  <img src={image1} alt="" />
                  <h2>Office Moving in Bangalore That's Efficient and Hassle-Free with JSS Packers and Movers </h2>
                  <p>
                  Office relocation is a huge undertaking that requires careful planning, organising, and carrying out. It is crucial to have a trustworthy and knowledgeable moving service in a busy city like Bangalore. Relocation experts JSS Packers and Movers provide all-inclusive office moving services that are intended to make your move easy and stress-free.
                  </p>
                  <p>With years of experience, JSS Packers and Movers is well-versed in  Packers & Movers and moving. Because their team of experts is knowledgeable about the nuances of office relocation, they can guarantee that every part of the move is managed with care and accuracy. They have successfully handled a wide spectrum of relocations, from tiny businesses to huge corporate offices. </p>

                  <p>The business offers comprehensive office relocation services. Planning, packing, loading, transporting, unloading, and unpacking are all included in their services. Additionally, they provide specialised services including safe document relocation, furniture disassembly and reassembly, and handling of IT equipment. All of your office's assets will be relocated effectively and securely thanks to this all-encompassing strategy.</p>
                  <h3>JSS Packers & Movers: Office Shifting Service </h3>
                  <img src={image2} alt="" />
                  <p>Since every office relocation is different, JSS Packers & Movers provides individualised moving schedules. They carefully evaluate your needs and come up with a plan that fits your deadlines and particular specifications. There will be as little disruption to your business operations as possible with this customised approach.</p>
                  <h5>Are you searching for the best Packers & Movers services in the Banglore? JSS Packers & Movers offers reliable and innovative solutions.</h5>
                  <p>Following packaging, the goods are carefully placed into their well-kept cars. The safe handling of large, heavy objects is guaranteed by the use of contemporary lifting equipment. Their drivers, who are accustomed to handling Bangalore's traffic, guarantee a punctual and seamless journey. The group takes equal care in unloading and unpacking the belongings after arriving at the destination. They can help you rapidly set up your workplace by assisting to arrange the furniture and equipment according to your specifications.

                  </p>

                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="page1-left-text">
                      <h6>JSS Packers & Movers: Seamlessly Navigate Office Shifitng Service </h6>
                      <p>
                      Office moving might be a difficult undertaking, but with Bangalore's JSS Packers and Movers, you can have a seamless move. Their proficiency, all-inclusive offerings, and dedication to excellence render them the perfect collaborator for your workplace relocation. Selecting JSS Packers and Movers guarantees a smooth transfer to your new office space with the least amount of interference to your company's activities.

                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="page1-right-pic">
                      <img src={image3} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </section>

      <Process />
    </>
  );
}

export default AirFreight;


