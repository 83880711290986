import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import image1 from "../Assets/ocean1.webp";
import image2 from "../Assets/ocean2.webp"
import image3 from "../Assets/ocean3jpeg.webp";
import { FaAngleRight, FaEnvelope, FaPencilAlt, FaPhoneAlt, FaUserAlt } from 'react-icons/fa'
import Process from './Process';
import heavyhaul from "../Assets/cargo.webp"


function Ocean() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
<>
  
  <section className="page-title page-titles">
    <div className="container">
      <div className="banner-section">
       <div className=" page-content">
       <h1 className="title">Home Shifting Service</h1>
        <ul className="page-breadcrumb">
         
          <li>Moving to a new home can be both exciting and stressful. JSS Packers and Movers make home shifting in Bangalore a breeze with their professional and personalized services. They handle every aspect of the move, from packing your belongings with care to transporting them safely to your new home. Their experienced team ensures that your possessions arrive at their destination intact and on time, allowing you to settle into your new space with ease.</li>
        </ul>
       </div>
      <img src={heavyhaul} alt="" />
      </div>

    </div>
  </section> 

    <section className="single-service">
      <div className="container">
        <div className="row">
        <div className="col-md-4">
          <div className="service-page1-right">
              <div className="service-menu">
                <h6>Service Menu</h6>
                <ul>
                <Link to="/packers-movers-in-banglore"> <li>Packers & Movers <FaAngleRight /></li></Link> 
                <Link to="/office-shifting-in-banglore">  <li>Office Shifting <FaAngleRight /></li></Link> 
                <Link to="/home-shifting-in-banglore">  <li>Home Shifting<FaAngleRight /></li></Link> 
                <Link to="/storage-facilities-in-banglore">  <li>Storage Facilities<FaAngleRight /></li></Link> 
                <Link to="/bike-car-shifting-in-banglore">  <li>Bike & Car Shifting <FaAngleRight /></li></Link> 
                <Link to="/international-shifting-in-banglore">   <li>International Shifting <FaAngleRight /></li></Link> 
                 
                 
                </ul>
              </div>
              <div className="service-menu">
                <h6>Fill The Form</h6>
                <div className="form-fill">  <span>
                  <FaUserAlt /></span>
                  <input type="text" placeholder="Full Name" />
                  <span><FaEnvelope /></span>
                  <input type="email" placeholder="Your Email" />
                  <span><FaPhoneAlt /></span>
                  <input type="number" placeholder="Phone Number" />
                  <span><FaPencilAlt /></span>
                  <textarea name="" id="" cols="30" rows="7"></textarea>
                  <button>Send Now</button>
                </div>
              </div>
            </div> 
          </div>
          <div className="col-md-8">
            <div className="service-page1-left">
              <div className="page1-img-text">
                <img src={image1} alt="" />
                <h2>JSS Packers and Movers: Your Reliable Partner for Home Shifting in Bangalore </h2>
                <p>
                Relocating to a new residence is an exciting time in life, but there are typically a lot of stress and practical difficulties involved. This is where Bangalore's JSS Packers and Movers come into play, providing a full range of house moving services meant to make your relocation easy, hassle-free, and efficient. As one of the top moving firms in Bangalore, JSS Packers and Movers is distinguished by its reputation for dependability and professionalism.
                </p>
                <p>Every home is different, and so are our clients' relocation demands, and we at JSS Packers and Movers recognise this. We can manage anything from little apartments to enormous houses because to the way our home shifting services are designed to meet a variety of needs.</p>
                <p>Pre-Move Survey and Planning: In order to determine the quantity and kind of objects that need to be relocated, we begin with a thorough pre-move survey. Our knowledgeable surveyors determine the precise needs, organise the  Packers & Movers, and offer an upfront quotation. One of the most important moving-related tasks is packing. Our knowledgeable packers take great care to protect your possessions by using premium packing supplies and methods. We handle everything with the utmost care, from heavy furniture to delicate objects like electronics and glassware.
                </p>
                <h3>Why Choose JSS Packers and Movers?
                </h3>
                <img src={image2} alt="" />
                <p>Professionalism: Trained experts committed to delivering the best possible service make up our team. In all of our interactions, we uphold the highest standards of professionalism and integrity.

Experience and Knowledge: We have the knowledge to manage any kind of transfer thanks to our many years of moving industry experience. Our background enables us to anticipate difficulties and take proactive measures to overcome them.
Customer satisfaction is our first goal at JSS Packers and Movers. We take a customer-centric approach. We pay close attention to your demands, respond to your worries, and put forth endless effort to make sure you have a great moving experience.

Safety and Security: Your items' safety and security are very important to us. Our cargo trucks are outfitted with the newest technology, and we use the best packing supplies available.
                </p>
                <h5>JSS Packers & Movers: Testimonials and Client Feedback</h5>
                <p>Positive comments we receive from our clients demonstrate our dedication to quality. Numerous clients have complimented us on our effectiveness, dependability, and the careful handling of their possessions. Our success stories demonstrate our commitment to making every endeavour a triumph.
                Go no farther than JSS Packers and Movers if you're preparing to move in Bangalore. For all of your house moving needs, our skilled staff is here to help. Get a free quote from us right now to see what it's like to deal with Bangalore's top moving company.</p>

              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="page1-left-text">
                    <h6>Navigating Home Shifting Service Seamlessly with JSS Packers and Movers </h6>
                    <p>To sum up, JSS Packers and Movers provides a dependable, effective, and client-centered house moving service in Bangalore. Our team's knowledge, dedication to quality, and complete solutions enable us to provide stress-free and pleasurable moving experiences. Put your trust in us for your next relocation, and become one of the many happy clients who have already felt the JSS difference.</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="page1-right-pic">
                    <img src={image3} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
     
        </div>
      </div>
    </section>
    <Process />
    </>
  );
}

export default Ocean;


