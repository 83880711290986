import React, { useEffect } from 'react';
import { FaAngleRight, FaEnvelope, FaPencilAlt, FaPhoneAlt, FaUserAlt } from 'react-icons/fa'
import heavyhaul from "../Assets/heavy-industrial.webp"
import { Link } from 'react-router-dom';
import image1 from "../Assets/roadways.webp"
import image2 from "../Assets/24177.webp"
import image3 from "../Assets/truck.webp"
import Process from './Process';

function HeavyHaul() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
<>
    <section className="page-title page-titles">
    <div className="container">
      <div className="banner-section">
       <div className=" page-content">
       <h1 className="title">Packers & Movers Service</h1>
        <ul className="page-breadcrumb">
         
          <li>When it comes to finding reliable and efficient packers and movers in Bangalore, JSS Packers and Movers stand out as the best in the business. With a comprehensive range of services including home shifting, office shifting, bike and car shifting, international shifting, and storage services, JSS Packers and Movers have established themselves as the go-to solution for all your moving needs in Bangalore.</li>
        </ul>
       </div>
      <img src={heavyhaul} alt="" />
      </div>

    </div>
  </section> 

    <section className="single-service">
      <div className="container">
        <div className="row">
        <div className="col-md-4">
          <div className="service-page1-right">
              <div className="service-menu">
                <h6>Service Menu</h6>
                <ul>
                <Link to="/packers-movers-in-banglore"> <li>Packers & Movers <FaAngleRight /></li></Link> 
                <Link to="/office-shifting-in-banglore">  <li>Office Shifting <FaAngleRight /></li></Link> 
                <Link to="/home-shifting-in-banglore">  <li>Home Shifting<FaAngleRight /></li></Link> 
                <Link to="/storage-facilities-in-banglore">  <li>Storage Facilities<FaAngleRight /></li></Link> 
                <Link to="/bike-car-shifting-in-banglore">  <li>Bike & Car Shifting <FaAngleRight /></li></Link> 
                <Link to="/international-shifting-in-banglore">   <li>International Shifting <FaAngleRight /></li></Link> 
                 
                 
                </ul>
              </div>
              <div className="service-menu">
                <h6>Fill The Form</h6>
                <div className="form-fill">  <span>
                  <FaUserAlt /></span>
                  <input type="text" placeholder="Full Name" />
                  <span><FaEnvelope /></span>
                  <input type="email" placeholder="Your Email" />
                  <span><FaPhoneAlt /></span>
                  <input type="number" placeholder="Phone Number" />
                  <span><FaPencilAlt /></span>
                  <textarea name="" id="" cols="30" rows="7"></textarea>
                  <button>Send Now</button>
                </div>
              </div>
            </div> 
          </div>
          <div className="col-md-8">
            <div className="service-page1-left">
              <div className="page1-img-text">
                <img src={image1} alt="" />
                <h2>Bangalore's Reliable Moving Partner: JSS Packers & Movers </h2>
                <p>
                Moving to a new location may be a difficult undertaking, whether it's across town or state lines. Careful planning and dependable execution are necessary for the  Packers & Movers, packing, and secure shipping of possessions. In Bangalore, JSS Packers and Movers is a well-known name in the moving services industry. In the busy metropolis of Bangalore, JSS Packers and Movers has made a name for itself thanks to its professionalism and customer-first mentality.
                </p>
               
                <h3>Concerning JSS Packers and Movers</h3>
                <img src={image2} alt="" />
                <p>With its headquarters located in Bangalore, JSS Packers and Movers is a top relocation service provider that provides all-inclusive solutions for moving needs in the residential, commercial, and industrial sectors. Having worked in the field for years, they have a thorough understanding of the the complexities of moving and packing. Their goal is to make moving less stressful for their consumers by providing them with services that facilitate a smooth transfer.
                </p>
                <h5>Are you searching for the best Packers & Movers  for reliable
                  shifting? </h5>
                <p>JSS Packers and Movers provides a broad range of services that are suited to their clients' various needs:
                </p>

              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="page1-left-text">
                    <p>Residential Relocation: Relocating may be very stressful on the body and the emotions. JSS Packers & Movers manages every facet of home moving, from carefully packing furniture to guaranteeing secure transit and unpacking at the other place. Their staff is skilled at handling large furniture, electronics, and breakable objects with equal proficiency.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="page1-right-pic">
                    <img src={image3} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </section>

    <Process />
    </>
  );
}

export default HeavyHaul;


